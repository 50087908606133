<div class="box mtop-32">
  <div>
    <h6 class="commissioning-header col-sm-12 text-center">
      {{ "cloudConnector" | translate: "Commissioning" }}
    </h6>
  </div>
  <div class="value-header">
    {{ "cloudConnectorId" | translate: "Commissioning" }}
  </div>
  <div class="value" *ngIf="gatewayData.GatewayKey?.trim()">
    {{ gatewayData.GatewayKey }}
  </div>
  <div class="value" *ngIf="!gatewayData.GatewayKey?.trim()">
    {{ "notAvailable" | translate: "Commissioning" }}
  </div>
  <div class="value-header">{{ "ipAddress" | translate: "Commissioning" }}</div>
  <div class="value" *ngIf="gatewayData.IPAddress?.trim()">
    {{ gatewayData.IPAddress }}
  </div>
  <div class="value" *ngIf="!gatewayData.IPAddress?.trim()">
    {{ "notAvailable" | translate: "Commissioning" }}
  </div>
  <div class="value-header">
    {{ "signalStrength" | translate: "Commissioning" }}
  </div>

  <div class="signalStrength">
    <svg
      style="height: 25px; width: 25px"
      [ngClass]="getColorClass(gatewayData.SignalStrength)"
    >
      <use
        [attr.xlink:href]="
          '../../../assets/icons-svg/sprite-svg-icons.svg#' +
          getColorClass(gatewayData.SignalStrength)
        "
      ></use>
    </svg>
    <span class="signalStrength-value">
      {{
        gatewayData.SignalStrength
          ? gatewayData.SignalStrength + "%"
          : ("noValue" | translate: "Commissioning")
      }}
    </span>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 text-center mtop-32">
      <button
        type="button"
        class="btn btn-primary cp"
        data-toggle="modal"
        data-target="#delete"
        [disabled]="disableDeleteFlag"
      >
        {{ "delete" | translate: "Commissioning" }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="routeToListGatewayDevicePage()"
      >
        {{ "cancel" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>
<div class="modal fade" id="delete" data-backdrop="true" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteCloudConnector" | translate: "Commissioning" }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deletecloudConnectorMsg" | translate: "Commissioning" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
          #addcloseBtn
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="deleteGateway()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
