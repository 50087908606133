<p-tabView
  class="tab-main"
  (onChange)="tabChange()"
  [(activeIndex)]="activeIndex"
>
  <p-tabPanel header="{{ 'messageList' | translate: 'Announcements' }}">
    <div>
      <app-commtasklist [reload]="refreshTaskList">
        (loadtasklist)="refreshTaskListData($event)>
      </app-commtasklist>
    </div>
  </p-tabPanel>
</p-tabView>
<div
  class="modal fade"
  id="commCreateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  (click)="showCommStepperModal()"
>
  <div class="modal-dialog tasklist-modal" role="document">
    <div class="modal-content tasklist-modal-cont">
      <div class="create-communication">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-commstepper *ngIf="showModal"></app-commstepper>
    </div>
  </div>
</div>
