import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { MessageService } from "primeng/api";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";

import { PennService } from "../common/penn.service";
import { HttpService } from "../common/services/http.service";
import { TranslateService } from "../common/services/translate.service";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";

import * as CryptoJS from "crypto-js";
import { Router } from "@angular/router";
import { getStatusList } from "../common/data/status-filter";
import { SessionVariable } from "../common/class/storageLabel";
import { UserData } from "../common/models/user.model";
import { ApiListResponse } from "../common/models/configuration.model";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
})
export class ClientComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("statusChangecloseBtn", { static: true })
  statusChangecloseBtn: ElementRef;
  // Title = "Client Master";
  Title: String;
  clientList;
  totalclientList: number;
  clientID: number;
  clientName: string;
  p: number = 1;
  cityList;
  selectedCity;
  stateList;
  selectedState;
  searchText;
  filterCities = [];
  filterStates = [];
  //cityFilter;
  userProfile;
  deleteAccess: boolean = true;
  statusText: string;
  statusFlag: boolean;
  addClientFlag;
  selectedStatus: any;
  statusList: any = [];
  selectCityplaceholderLabel;
  userList;
  clientHome = "clientPage";

  constructor(
    public pennService: PennService,
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.pennService.taskMgmtActive = false;
    this.pennService.hideSubNav();
    let routeURL = window.location.pathname.split("/")[1];
    this.storage.set("routename", routeURL);
    this.Title = this.translate.data.Client.client;
    this.storage.remove("showModule");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    var pagenumber = this.pennService.getStoredData("ClientpageNumber");

    if (pagenumber) {
      this.p = pagenumber;
      this.storage.remove("ClientpageNumber");
    }

    if (this.userProfile) {
      this.addClientFlag =
        this.userProfile.roleName === "Client Admin" ||
        this.userProfile.roleName === "Field Engineer"
          ? false
          : true;
      this.getClientURL();
    }
    this.selectCityplaceholderLabel = this.translate.data.Client.selectcity;
    this.statusList = getStatusList();
    this.pennService.hideNotificationPanel();
  }

  getClientURL() {
    let getClientAPIURL;
    if (
      this.userProfile.roleName == "Admin Reseller" ||
      this.userProfile.roleName == "Field Engineer"
    ) {
      getClientAPIURL =
        RestApi.get_client_mapping_by_userid + "/" + this.userProfile.userID;
      this.deleteAccess = true;
    } else if (this.userProfile.roleName == "Super Admin") {
      getClientAPIURL = RestApi.client_list;
      this.deleteAccess = true;
    } else if (this.userProfile.roleName == "Client Admin") {
      getClientAPIURL =
        RestApi.client_details + "/" + this.userProfile.clientID;
      this.deleteAccess = false;
    }
    this.getClientData(getClientAPIURL);
  }

  filterStatus() {
    this.getClientURL();
  }

  getClientData(url) {
    this.httpService.get<any>(url).subscribe((res: any) => {
      if (res.IsSuccess && res.Data) {
        if (this.userProfile.roleName == "Client Admin") {
          this.clientList = [res.Data];
          this.totalclientList = this.clientList.length;
        } else {
          if (this.selectedStatus && this.selectedStatus.id != 0) {
            this.clientList = res.Data.filter((ele) => {
              return ele.Status == this.selectedStatus.name;
            });
            this.totalclientList = this.clientList.length;
          } else {
            this.clientList = res.Data;
            this.totalclientList = res.Data.length;
          }
        }

        let cityflags = {};
        let stateflags = {};
        this.filterCities = [];
        this.filterStates = [];
        for (let i = 0; i < this.clientList.length; i++) {
          if (!cityflags[this.clientList[i].City.trim().toLowerCase()]) {
            cityflags[this.clientList[i].City.trim().toLowerCase()] = true;
            this.filterCities.push({ name: this.clientList[i].City });
          }
          if (!stateflags[this.clientList[i].State.trim().toLowerCase()]) {
            stateflags[this.clientList[i].State.trim().toLowerCase()] = true;
            this.filterStates.push({ name: this.clientList[i].State });
          }
        }

        this.cityList = this.filterCities;

        this.stateList = this.filterStates;
      }
    });
  }

  redirectToSite(val, item) {
    let flag = val == "clientPref" ? "true" : "false";
    this.storage.set("clientPrefFlag", flag);
    this.sessionStorage.set("clientPrefFlag", flag);
    this.pennService.saveDataToStorage("ClientpageNumber", this.p);

    let ClientID = val == "Add" ? 0 : item.ClientID;
    this.router.navigate(["/addClient", ClientID]);

    if (val == "clientPref" || val == "Edit") {
      this.pennService.saveDataToStorage("clientName", item.ClientName);
      this.sessionStorage.set("clientName", item.ClientName);
    } else {
      this.storage.remove("clientName");
    }
  }
  onSelectCity(val) {
    this.searchText = val ? val.name : "";
    if (this.selectedState) {
      this.selectedState = "";
    }
  }

  onSelectState(val) {
    this.searchText = val ? val.name : "";
    if (this.selectedCity) {
      this.selectedCity = "";
    }
  }

  getUsers() {
    this.httpService
      .get(RestApi.all_user_list)
      .subscribe((res: ApiListResponse<UserData>) => {
        this.userList = res.Data;
      });
  }

  getClientID(data) {
    this.clientID = data.ClientID;
    this.clientName = data.ClientName;
  }

  deleteClient() {
    let obj = {
      ClientID: this.clientID,
    };
    this.httpService.post(RestApi.delete_client, obj).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Popuop_Msg.clientdeletedsuccess,
        });
        this.addcloseBtn.nativeElement.click();
        this.getClientURL();
      } else {
        this.messageService.add({
          severity: "error",
          summary: res.ReturnMessage,
        });
      }
    });
  }

  setClientID(data) {
    this.pennService.saveDataToStorage(`clientID`, data.ClientID);
    this.sessionStorage.set("clientID", data.ClientID);
    this.pennService.saveDataToStorage("clientName", data.ClientName);
    this.sessionStorage.set("clientName", data.ClientName);
    this.pennService.saveDataToStorage("ClientpageNumber", this.p);
    this.sessionStorage.set("ClientpageNumber", this.p);
  }

  clientStatus(data) {
    this.clientID = data.ClientID;
    this.statusText = data.Status == "Active" ? "disable" : "enable";
    this.statusFlag = data.Status == "Active" ? false : true;
  }

  changeClientStatus() {
    this.httpService
      .get(
        RestApi.update_client_status +
          "/" +
          this.clientID +
          "/" +
          this.statusFlag
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.statusChangecloseBtn.nativeElement.click();
          this.getClientURL();
        }
      });
  }

  callbackGetUser(clickevent) {
    if (clickevent) {
      this.getUsers();
    }
  }
}
