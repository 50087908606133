import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { PennService } from "../../common/penn.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import {
  Reminder,
  BackgroundJob,
} from "src/app/common/models/configuration.model";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { ReminderTabs } from "src/app/common/constants/enums/instructionEnums";

@Component({
  selector: "app-reminder",
  templateUrl: "./reminder.component.html",
  styleUrls: ["./reminder.component.scss"],
})
export class ReminderComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("addSinglecloseBtn", { static: true })
  addSinglecloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("deletecloseOnceBtn", { static: true })
  deletecloseOnceBtn: ElementRef;

  reminderModel: Reminder;
  oldRemindermodel: Reminder;
  reminderList: Array<Reminder>;
  singleReminderList: BackgroundJob[];
  siteID: number;
  reminderlistcols = [
    { field: "Name", header: this.translate.data.Reminder.ReminderName },
    {
      field: "ReminderDescription",
      header: this.translate.data.Reminder.ReminderText,
    },
    {
      field: "CreatedBy",
      header: this.translate.data.Reminder.createdBy,
    },
  ];
  singlereminderlistcols = [
    { field: "TaskName", header: this.translate.data.Reminder.ReminderName },
    { field: "Description", header: this.translate.data.Reminder.ReminderText },
    {
      field: "CreatedBy",
      header: this.translate.data.Reminder.createdBy,
    },
  ];
  clientID: number;
  clientName: string;
  siteName: string;
  userProfile;
  hours = [];
  MonthDays = [];
  Daylist = [];
  Monthlist = [];

  selectedMonths: string[];
  selectedDays: string[];
  selectedHours: string[];

  addReminder: Reminder;
  addSingleReminder: BackgroundJob = <BackgroundJob>{};
  minDateValue: Date = new Date();

  modalTitle;
  routeUrl;
  selectedLink = "";

  CronTabMenuItem = { Daily: "", Weekly: "", Monthly: "", Yearly: "" };

  ReminderID;
  reminderfor = [];

  Durations = [];
  filteredDurations = [];

  Once = {
    ExecuteAt: new Date(),
  };

  Daily = {
    Recurring: "",
  };

  Weekly = {
    Days: [],
  };

  Monthly = {
    Day: 1,
    Recurring: 1,
  };

  Yearly = {
    Month: [],
  };

  Time = {
    From: new Date(),
    To: new Date(),
    Duration: 1,
    selectedRange: false,
  };
  //Bind these Value with data model
  timeTo: Date;
  timeFrom: Date;
  selectedRange: boolean;
  isOnceSelected: string;
  DurationValidationMessage: string = "";
  selectedMenu;
  sites = [];
  siteModel;
  siteList = [];
  selectRoleLabel = this.translate.data.moduleInstructionType.selectrole;
  currenttab = "recurring";
  activeIndex = 0;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.addReminder = <Reminder>{};
    this.routeUrl = this.storage.get("routename");
    this.clientID = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
    this.siteName = this.pennService.getStoredData("siteName");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.siteID = this.pennService.getStoredData("siteID");
    this.getData();
    this.showReminderPageBasedOnClientOrStore();
    this.pennService.showSubNav();
    //Remove hard coding and add to your constant
    this.selectedLink = "Daily";
  }

  MenuList: string[] = [];

  setradio(e) {
    this.selectedLink = e.name;
  }

  getReccuringReminderSite() {
    this.httpService.get(RestApi.GetReminders + this.siteID).subscribe(
      (res: any) => {
        this.reminderList = res.Data;
      },
      () => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.somethingwentwrong,
        });
      }
    );
  }

  private getSites(mode?, addReminder?) {
    if (this.sites.length === 0) {
      this.httpService
        .get(`${RestApi.client_site_list}/${this.clientID}`)
        .subscribe(
          (response: any) => {
            this.sites =
              // only show active site details
              response.IsSuccess && response.Data && response.Data != null
                ? response.Data.filter((site) => site.Status === "Active")
                : [];
            if (mode && addReminder) {
              this.filterSiteList(mode, this.addReminder);
            }
          },
          (_error: any) => (this.sites = [])
        );
    }
  }

  getSingleReminderSite() {
    this.httpService.get(RestApi.GetSingleReminders + this.siteID).subscribe(
      (res: any) => {
        this.singleReminderList = res.Data;
      },
      () => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.somethingwentwrong,
        });
      }
    );
  }

  getData() {
    this.httpService.get<any>("../assets/json/reminder.data.json").subscribe(
      (res) => {
        this.CronTabMenuItem = res.CronTabMenuItem;
        this.reminderfor = res.reminderfor;
        this.Durations = res.Durations;
        this.MenuList = res.MenuList;
        this.Monthlist = res.Months;
        this.Daylist = res.Days;
        this.selectedLink = "Daily";
      },
      () => {}
    );

    for (let i = 0; i < 24; i++) this.hours.push({ value: i, label: i });

    for (let i = 1; i <= 31; i++) this.MonthDays.push({ value: i, label: i });
  }

  showReminderPageBasedOnClientOrStore() {
    if (this.routeUrl === Constant.Routetype.site) {
      this.getReccuringReminderSite();
    } else if (this.routeUrl === Constant.Routetype.client) {
      this.getRecurringReminderClient();
    }
  }

  getRecurringReminderClient() {
    this.httpService
      .get(RestApi.GetRemindersForClient + this.clientID)
      .subscribe(
        (res: any) => {
          this.reminderList = res.Data;
        },
        () => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.somethingwentwrong,
          });
        }
      );
  }

  getSingleReminderClient() {
    this.httpService
      .get(RestApi.GetSingleRemidersForClinet + this.clientID)
      .subscribe(
        (res: any) => {
          this.singleReminderList = res.Data;
        },
        () => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.somethingwentwrong,
          });
        }
      );
  }

  showModal(ModalOption, Modaldata) {
    if (ModalOption == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.addReminder = <Reminder>{};
      this.ReminderID = 0;
      this.Time.From = new Date();
      this.Time.To = new Date();
      this.getSites("add", this.addReminder);
    } else if (ModalOption == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.addReminder = Object.assign({}, Modaldata);
      this.oldRemindermodel = Object.assign({}, Modaldata);
      this.ReminderID = Modaldata.ReminderID;
      this.getSites("edit", this.addReminder);

      let CronJson = JSON.parse(Modaldata.CronJson);

      this.selectedLink = CronJson.selectedLink;
      this.selectedMenu = this.MenuList[
        this.setDurationRadioOption(this.selectedLink)
      ];
      this.Weekly = CronJson.Weekly;
      this.Daily = CronJson.Daily;
      this.Yearly = CronJson.Yearly;
      this.Time.From = new Date(CronJson.Time.From);
      this.Time.To = new Date(CronJson.Time.To);
      this.Time.Duration = CronJson.Time.Duration;
      this.Time.selectedRange = CronJson.Time.selectedRange;
    } else if (ModalOption == Constant.CommandType.delete) {
      Modaldata.who = this.routeUrl === "site" ? "Site" : "Client";
      Modaldata.SiteID = this.siteID;
      this.reminderModel = Modaldata;
    }
  }

  showSingleModal(ModalOption, Modaldata: BackgroundJob) {
    if (ModalOption == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.Once.ExecuteAt = new Date();
      this.addSingleReminder = <BackgroundJob>{};
      this.addSingleReminder.TaskID = 0;
      this.addSingleReminder.ExecuteAt = this.Once.ExecuteAt;
      this.filterSiteList("add", this.addSingleReminder);
    } else if (ModalOption == Constant.CommandType.edit) {
      this.addSingleReminder = Modaldata;
      this.filterSiteList("edit", this.addSingleReminder);
      this.Once.ExecuteAt =
        new Date(this.addSingleReminder.ExecuteAt) > new Date()
          ? new Date(this.addSingleReminder.ExecuteAt)
          : new Date();
    } else if (ModalOption == Constant.CommandType.delete) {
      Modaldata.who = this.routeUrl === "site" ? "Site" : "Client";
      Modaldata.SiteID = this.siteID;
      this.addSingleReminder = Modaldata;
      this.filterSiteList("edit", this.addSingleReminder);
      this.Once.ExecuteAt =
        new Date(this.addSingleReminder.ExecuteAt) > new Date()
          ? new Date(this.addSingleReminder.ExecuteAt)
          : new Date();
    }
  }

  setDurationRadioOption(data: string): number {
    let i = 0;
    this.MenuList.forEach((ele: any, index: number) => {
      if (data === ele.name) {
        i = index;
      }
    });
    return i;
  }

  saveReminder() {
    this.saveReminderforClientOrStore(this.addReminder);
    this.addReminder.CreatedOn = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addReminder.CreatedBy = this.userProfile.userName;
    this.addReminder.CreatedByID = this.userProfile.userID;
    this.addReminder.Cron = this.getCron();
    this.addReminder.ClientID = this.clientID;

    this.httpService
      .post(RestApi.SaveReminder, {
        RequestUri: RestApi.SaveNotification,
        Reminder: this.addReminder,
      })
      .subscribe(
        (res: any) => {
          if (res.IsSuccess) {
            let msg =
              this.ReminderID > 0
                ? this.translate.data.Popuop_Msg.reminderupdatedsuccessfully
                : this.translate.data.Popuop_Msg.reminderaddedsuccessfully;
            this.messageService.add({
              severity: ToastMsg.severity.success,
              detail: msg,
              summary:
                this.ReminderID > 0
                  ? this.translate.data.Popuop_Msg.updated
                  : this.translate.data.Popuop_Msg.saved,
            });
            this.getrecurringReminder();
            this.addcloseBtn.nativeElement.click();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              detail: res.ReturnMessage,
              summary: this.translate.data.Popuop_Msg.error,
            });
          }
        },
        () => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            detail: this.translate.data.Popuop_Msg.someInternalError,
            summary: this.translate.data.Popuop_Msg.error,
          });
        }
      );
  }

  getrecurringReminder() {
    if (this.routeUrl === Constant.Routetype.site) {
      this.getReccuringReminderSite();
    } else if (this.routeUrl === Constant.Routetype.client) {
      this.getRecurringReminderClient();
    }
  }

  saveReminderforClientOrStore(dataobject) {
    if (this.routeUrl === Constant.Routetype.site) {
      dataobject.SiteID = this.siteID;
      dataobject.Sites = this.siteID.toString();
      dataobject.Level = "Site";
    } else if (this.routeUrl === Constant.Routetype.client) {
      dataobject.Sites = this.siteModel.map((site) => site.id).toString();
      dataobject.SiteID = dataobject.Sites.split(",")[0];
      dataobject.Level = "Client";
    }
  }

  saveSingleReminder() {
    this.saveReminderforClientOrStore(this.addSingleReminder);
    this.addSingleReminder.CreatedOn = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addSingleReminder.CreatedBy = this.userProfile.userName;
    this.addSingleReminder.CreatedByID = this.userProfile.userID;
    this.addSingleReminder.ExecuteAt = this.Once.ExecuteAt;
    this.addSingleReminder.ClientID = this.clientID;
    this.httpService
      .post(RestApi.SaveSingleReminder, {
        RequestUri: RestApi.SaveNotification,
        Reminder: this.addSingleReminder,
      })
      .subscribe(
        (res: any) => {
          if (res.IsSuccess) {
            let msg =
              this.addSingleReminder.TaskID > 0
                ? this.translate.data.Popuop_Msg.reminderupdatedsuccessfully
                : this.translate.data.Popuop_Msg.reminderaddedsuccessfully;
            this.messageService.add({
              severity: ToastMsg.severity.success,
              detail: msg,
              summary:
                this.ReminderID > 0
                  ? this.translate.data.Popuop_Msg.update
                  : this.translate.data.Popuop_Msg.saved,
            });
            this.getsingleReminder();
            this.addSinglecloseBtn.nativeElement.click();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              detail: res.ReturnMessage,
              summary: this.translate.data.Popuop_Msg.error,
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.error,
            detail: this.translate.data.Popuop_Msg.someInternalError,
          });
        }
      );
  }

  getsingleReminder() {
    if (this.routeUrl === Constant.Routetype.site) {
      this.getSingleReminderSite();
    } else if (this.routeUrl === Constant.Routetype.client) {
      this.getSingleReminderClient();
    }
  }

  deleteReminder() {
    this.httpService.post(RestApi.DeleteReminder, this.reminderModel).subscribe(
      (res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            detail: this.translate.data.Popuop_Msg.reminderdeletedsuccessfully,
            summary: this.translate.data.Popuop_Msg.deleted,
          });
          this.deletecloseBtn.nativeElement.click();
          this.getrecurringReminder();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Popuop_Msg.error,
            detail: res.ReturnMessage,
          });
        }
      },
      () => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          detail: this.translate.data.Popuop_Msg.someInternalError,
          summary: this.translate.data.Popuop_Msg.error,
        });
      }
    );
  }

  deleteSingleReminder() {
    this.httpService
      .post(RestApi.DeleteSingleReminder, this.addSingleReminder)
      .subscribe(
        (res: any) => {
          if (res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              detail: this.translate.data.Popuop_Msg
                .reminderdeletedsuccessfully,
              summary: this.translate.data.Popuop_Msg.deleted,
            });
            this.deletecloseOnceBtn.nativeElement.click();
            this.getsingleReminder();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: ToastMsg.reminder.error,
              detail: res.ReturnMessage,
            });
          }
        },
        () => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            detail: this.translate.data.Popuop_Msg.someInternalError,
            summary: this.translate.data.Popuop_Msg.error,
          });
        }
      );
  }

  Validate(): boolean {
    return (
      !this.addReminder.Name ||
      !this.addReminder.ReminderDescription ||
      !this.addReminder.ReminderFor ||
      !this.checkDurationInterval()
    );
  }

  ValidateBackgroundJob(): boolean {
    return (
      !this.addSingleReminder.TaskName ||
      !this.addSingleReminder.Description ||
      !this.addSingleReminder.Reminderfor ||
      !this.Once.ExecuteAt
    );
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
  }

  getCron(): string {
    var Cron = "0 "; //{Minute} {hour} {day of Month} {Month} {day}

    let Hour =
      this.Time.From.getUTCHours() +
      (this.Time.selectedRange
        ? "-" + this.Time.To.getUTCHours() + "/" + this.Time.Duration
        : "");

    if (this.selectedLink == this.CronTabMenuItem.Daily) {
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} * * ${
        this.Daily.Recurring == "" || this.Daily.Recurring == "1"
          ? "*"
          : "1/" + this.Daily.Recurring
      }`;
    } else if (this.selectedLink == this.CronTabMenuItem.Weekly) {
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} * * ${this.Weekly.Days.join(
        ","
      )}`;
    } else if (this.selectedLink == this.CronTabMenuItem.Monthly) {
      let currentMonth = new Date().getUTCMonth() + 1;
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} ${
        this.Monthly.Day
      } ${currentMonth}/${this.Monthly.Recurring} *`;
    } else if (this.selectedLink == this.CronTabMenuItem.Yearly) {
      Cron = `${this.Time.From.getUTCMinutes()} ${Hour} 1 ${this.Yearly.Month.join(
        ","
      )} *`;
    }

    this.addReminder.CronJson = JSON.stringify({
      selectedLink: this.selectedLink,
      Hour: Hour,
      Weekly: this.Weekly,
      Daily: this.Daily,
      Monthly: this.Monthly,
      Yearly: this.Yearly,
      Time: this.Time,
    });

    return Cron;
  }

  checkDurationInterval(): boolean {
    let StartMinutes =
      Number(this.Time.From.getHours() * 60) +
      Number(this.Time.From.getMinutes());
    let EndMinutes =
      Number(this.Time.To.getHours() * 60) + Number(this.Time.To.getMinutes());
    let differenceMinute = EndMinutes - StartMinutes;
    let Hours = Math.floor(differenceMinute / 60);
    this.filteredDurations = this.Durations.filter((val: any, idx: number) => {
      return Number(val.value) <= Hours;
    });
    return this.Time.selectedRange ? this.filteredDurations.length > 0 : true;
  }

  filterSiteList(modalType, rowData) {
    this.siteModel = [];
    if (this.userProfile.roleName === "Site Manager") {
      this.sites.forEach((ele) => {
        if (ele.SiteID === this.userProfile.siteID) {
          this.siteList.push({
            label: ele.SiteName,
            value: { id: ele.SiteID, name: ele.SiteName },
          });
          this.siteModel.push({
            id: ele.SiteID,
            name: ele.SiteName,
          });
          return;
        }
      });
    } else {
      this.siteList = this.sites.map((ele) => {
        return {
          label: ele.SiteName,
          value: { id: ele.SiteID, name: ele.SiteName },
        };
      });
      if (modalType === "edit") {
        this.filterSiteListonEditFlow(rowData);
      }
    }
  }

  filterSiteListonEditFlow(rowData) {
    this.siteList.forEach((ele) => {
      if (rowData.Sites.split(", ").includes(ele.value.id.toString())) {
        this.siteModel.push({
          id: ele.value.id,
          name: ele.value.name,
        });
      }
    });
  }

  handleChange(data) {
    if (data.index == ReminderTabs.Recurring) {
      this.currenttab = "recurring";
      this.activeIndex = 0;
    } else if (data.index == ReminderTabs.OneTime) {
      this.currenttab = "onetime";
      this.activeIndex = 1;
      if (this.routeUrl === Constant.Routetype.site) {
        this.getSingleReminderSite();
      } else if (this.routeUrl === Constant.Routetype.client) {
        this.getSingleReminderClient();
      }
    }
  }
}
