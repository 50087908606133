import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { PennService } from "../../common/penn.service";
import { ExcelService } from "../../common/services/excel.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";

import {
  ClientProduct,
  SiteProduct,
  MapProductSupplier,
  ApiListResponse,
  ClientSupplier,
} from "../../common/models/configuration.model";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { getStatusList } from "../../common/data/status-filter";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Table } from "primeng/table";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("paddcloseBtn", { static: true }) paddcloseBtn: ElementRef;
  @ViewChild("pdeletecloseBtn", { static: true }) pdeletecloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("mapSupplierModal", { static: true }) mapSupplierModal: ElementRef;
  @ViewChild("viewmapSupplierModal", { static: true })
  viewmapSupplierModal: ElementRef;
  @ViewChild("statusChangecloseBtn", { static: true })
  statusChangecloseBtn: ElementRef;
  @ViewChild("inputFileUpload", { static: false }) inputFileUpload: ElementRef;

  clientProduct: ClientProduct;
  siteProduct: SiteProduct;
  mapProductSupplier: MapProductSupplier;
  productList;
  siteProductList;
  productlistcols;
  allproductlistcols;
  ProductName = [];
  modalTitle;
  createdDate;
  addbsUFlag: boolean = false;
  updatebsUFlag: boolean = false;
  defaultselect;
  routeUrl;
  clientID: number;
  clientName: string;
  siteID: number;
  siteName: string;
  productID: number;
  productName: string;
  selectedProducts: [];
  allProductList;
  selProducts;
  getSupplierList;
  allSuppliersList;
  selectedSupplier;
  selectedMappedSupplier;
  mappedSupplierList;
  selectedMapSuppliers;
  userProfile;
  oldProductObj: ClientProduct;
  statusText: string;
  statusFlag: boolean;
  selectedStatus: any;
  statusList: any = [];
  translateAssignSupplierBtnTooltip = "";
  selectLabel = "";
  @ViewChild("producttable", { static: false }) dataTable: Table;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private pennService: PennService,
    private excelService: ExcelService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.clientProduct = <ClientProduct>{};
    this.siteProduct = <SiteProduct>{};
    this.mapProductSupplier = <MapProductSupplier>{};
    this.routeUrl = this.storage.get("routename");
    // this.clientID = this.pennService.getStoredData("clientID");
    // this.clientName = this.pennService.getStoredData("clientName");
    // this.siteID = this.pennService.getStoredData("siteID");
    // this.siteName = this.pennService.getStoredData("siteName");

    if (this.routeUrl === "client") {
      this.clientID = Number(this.route.snapshot.paramMap.get("id"));
      this.clientName = this.route.snapshot.paramMap.get("name");
    } else if (this.routeUrl === "site") {
      //this.clientID = this.pennService.getStoredData("clientID");
      this.clientID = Number(this.route.snapshot.paramMap.get("clientid"));
      this.siteID = Number(this.route.snapshot.paramMap.get("id"));
      this.siteName = this.route.snapshot.paramMap.get("name");
    }
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    this.statusList = getStatusList();
    this.getProducts();
    this.getSupplier();
    this.getSiteProducts();
    this.pennService.showSubNav();
    this.translateAssignSupplierBtnTooltip = this.translate.data.Product.assingSupplier;
    this.selectLabel = this.translate.data.moduleBuilder.select;
  }

  downloadTemplate() {
    this.pennService.downloadExcelTemplate("Product");
  }

  getProducts() {
    this.httpService
      .get(RestApi.client_product_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<ClientProduct>) => {
        if (this.selectedStatus && this.selectedStatus.id != 0) {
          this.productList = res.Data.filter((ele) => {
            return ele.Status == this.selectedStatus.name;
          });
        } else {
          this.productList = res.Data;
        }

        this.ProductName = [];
        this.productlistcols = [
          {
            field: "ProductName",
            header: this.translate.data.Product.productName,
          },
          {
            field: "Description",
            header: this.translate.data.Common.description,
          },
          {
            field: "OpenedDays",
            header: this.translate.data.Common.chilledDays,
          },
          {
            field: "FrozenDays",
            header: this.translate.data.Common.frozenDays,
          },
          {
            field: "DefrostedDays",
            header: this.translate.data.Common.defrostedDays,
          },
        ];

        if (this.productList) {
          this.productList.forEach((res) => {
            this.ProductName.push({
              label: res.ProductName,
              value: res.ProductName,
            });
          });
        }
      });
  }

  filterStatus() {
    this.getProducts();
  }

  onFileChange(items: Blob[]) {
    this.excelService
      .sendExcelFile(
        items[0],
        RestApi.import_product,
        this.translate.data.Popuop_Msg.productimportedsuccessfully
      )
      .then((_) => {
        this.getProducts();
      });
    this.inputFileUpload.nativeElement.value = null;
  }

  importExcel() {
    document.getElementById("inputFileUpload").click();
  }

  getSiteProducts() {
    this.selProducts = [];
    this.httpService
      .get(RestApi.site_product_list + "/" + this.siteID)
      .subscribe((res: any) => {
        this.siteProductList = res.Data.ProductList;

        this.allproductlistcols = [
          {
            field: "productName",
            header: this.translate.data.Product.productName,
          },
        ];
      });
  }

  showproductModal(val: string, data) {
    if (val == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.clientProduct = <ClientProduct>{};
      this.productID = 0;
      this.clientProduct.OpenedDays = 0;
      this.clientProduct.DefrostedDays = 0;
      this.clientProduct.FrozenDays = 0;
    } else if (val == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.clientProduct = Object.assign({}, data);
      this.oldProductObj = Object.assign({}, data);
      this.productID = data.ProductID;
    } else if (val == Constant.CommandType.delete) {
      this.productID = data.ProductID;
      this.productName = data.ProductName;
    } else if (val == "Assign") {
      this.productID = data.ProductID;
      this.productName = data.ProductName;
    }
  }

  saveProduct() {
    this.clientProduct.ProductID = this.productID;
    this.clientProduct.ClientID = this.clientID;
    this.clientProduct.ClientName = this.clientName;
    this.clientProduct.CreatedOn = this.createdDate;
    this.clientProduct.CreatedBy = this.userProfile.userName;
    this.clientProduct.CreatedByID = this.userProfile.userID;
    this.clientProduct.ModifiedOn = this.createdDate;
    this.clientProduct.ModifiedBy = this.userProfile.userName;
    this.clientProduct.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.save_product, this.clientProduct)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.productID > 0
              ? this.translate.data.Popuop_Msg.productupdatedsuccessfully
              : this.translate.data.Popuop_Msg.productaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.addcloseBtn.nativeElement.click();
          this.getProducts();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteProduct() {
    let obj = {
      ProductID: this.productID,
    };
    this.httpService.post(RestApi.delete_product, obj).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.messageService.add({
          severity: ToastMsg.severity.success,
          summary: this.translate.data.Popuop_Msg.productdeletedsuccessfully,
        });
        this.deletecloseBtn.nativeElement.click();
        this.getProducts();
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: res.ReturnMessage,
        });
      }
    });
  }

  showMapProductModal() {
    this.selectedProducts = [];
    if (this.siteProductList.length == 0) {
      this.allProductList = this.productList;
    } else {
      this.allProductList = this.productList.filter(
        (all) =>
          !this.siteProductList.find((sel) => all.ProductID === sel.productID)
      );
    }
  }

  mapSelProducts() {
    let productList = [];
    this.selectedProducts.filter((ele: any) => {
      productList.push({
        productID: ele.ProductID,
        productName: ele.ProductName,
      });
    });

    this.siteProduct.SiteID = this.siteID;
    this.siteProduct.ClientID = this.clientID;
    this.siteProduct.ClientName = this.clientName;
    this.siteProduct.ProductList = productList;
    this.siteProduct.CreatedOn = this.createdDate;
    this.siteProduct.CreatedBy = this.userProfile.userName;
    this.siteProduct.CreatedByID = this.userProfile.userID;
    this.siteProduct.ModifiedOn = this.createdDate;
    this.siteProduct.ModifiedBy = this.userProfile.userName;
    this.siteProduct.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.map_site_products, this.siteProduct)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.productaddedsuccessfully,
          });
          this.paddcloseBtn.nativeElement.click();
          this.getSiteProducts();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteMappedProducts() {
    this.siteProduct.SiteID = this.siteID;
    this.siteProduct.ClientID = this.clientID;
    this.siteProduct.ProductList = this.selProducts;
    this.siteProduct.CreatedOn = this.createdDate;
    this.siteProduct.CreatedBy = this.userProfile.userName;
    this.siteProduct.CreatedByID = this.userProfile.userID;
    this.siteProduct.ModifiedOn = this.createdDate;
    this.siteProduct.ModifiedBy = this.userProfile.userName;
    this.siteProduct.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.unmap_site_products, this.siteProduct)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: "success",
            summary: this.translate.data.Popuop_Msg.productdeletedsuccessfully,
          });
          this.pdeletecloseBtn.nativeElement.click();
          this.getSiteProducts();
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }

  getSupplier() {
    this.httpService
      .get(RestApi.client_supplier_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<ClientSupplier>) => {
        this.getSupplierList = res.Data;
      });
  }

  showMapSupplierModal(data) {
    this.selectedSupplier = [];
    this.productName = data.ProductName;
    let mappedSupplier;
    this.productID = data.ProductID;
    this.oldProductObj = Object.assign({}, data);
    this.httpService
      .get(RestApi.client_product_supplier_list + "/" + this.productID)
      .subscribe((res: any) => {
        if (res.Data) {
          mappedSupplier = res.Data.SupplierList;
          this.allSuppliersList = this.getSupplierList.filter(
            (all) =>
              !mappedSupplier.find((sel) => all.SupplierID === sel.supplierID)
          );
        } else {
          this.allSuppliersList = this.getSupplierList;
        }
      });
  }

  viewMappedSupplierModal(data) {
    this.selectedMappedSupplier = [];
    this.productName = data.ProductName;
    this.productID = data.ProductID;
    this.httpService
      .get(RestApi.client_product_supplier_list + "/" + this.productID)
      .subscribe((res: any) => {
        if (res.Data) {
          this.mappedSupplierList = res.Data.SupplierList;
        } else {
          this.mappedSupplierList = [];
        }
      });
  }

  mapSelSupplier() {
    let supplierList = [];
    this.selectedSupplier.filter((ele: any) => {
      supplierList.push({
        supplierID: ele.SupplierID,
        supplierName: ele.SupplierName,
      });
    });
    this.mapProductSupplier.ProductID = this.productID;
    this.mapProductSupplier.ProductName = this.productName;
    this.mapProductSupplier.SupplierList = supplierList;
    this.mapProductSupplier.CreatedOn = this.createdDate;
    this.mapProductSupplier.CreatedBy = this.userProfile.userName;
    this.mapProductSupplier.CreatedByID = this.userProfile.userID;
    this.mapProductSupplier.ModifiedOn = this.createdDate;
    this.mapProductSupplier.ModifiedBy = this.userProfile.userName;
    this.mapProductSupplier.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.map_product_supplier, this.mapProductSupplier)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .supplierassignedsuccessfully,
          });
          this.mapSupplierModal.nativeElement.click();
          this.getSupplier();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  unmapSelSupplier() {
    this.mapProductSupplier.ProductID = this.productID;
    this.mapProductSupplier.ProductName = this.productName;
    this.mapProductSupplier.SupplierList = this.selectedMappedSupplier;
    this.mapProductSupplier.CreatedOn = this.createdDate;
    this.mapProductSupplier.CreatedBy = this.userProfile.userName;
    this.mapProductSupplier.CreatedByID = this.userProfile.userID;
    this.mapProductSupplier.ModifiedOn = this.createdDate;
    this.mapProductSupplier.ModifiedBy = this.userProfile.userName;
    this.mapProductSupplier.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.unmap_product_supplier, this.mapProductSupplier)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.supplierdeletedsuccessfully,
          });
          this.viewmapSupplierModal.nativeElement.click();
          this.getSupplier();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  clearModal() {
    this.clientProduct = <ClientProduct>{};
  }

  Validate() {
    if (this.productID == 0) return !this.clientProduct.ProductName;
    else if (this.productID > 0)
      return (
        !this.clientProduct.ProductName ||
        (this.clientProduct.ProductName == this.oldProductObj.ProductName &&
          this.clientProduct.Description == this.oldProductObj.Description &&
          this.clientProduct.OpenedDays == this.oldProductObj.OpenedDays &&
          this.clientProduct.FrozenDays == this.oldProductObj.FrozenDays &&
          this.clientProduct.DefrostedDays == this.oldProductObj.DefrostedDays)
      );
  }

  changeStatus(data) {
    this.productID = data.ProductID;
    this.statusText = data.Status == "Active" ? "disable" : "enable";
    this.statusFlag = data.Status == "Active" ? false : true;
  }

  changeProductStatus() {
    this.httpService
      .get(
        RestApi.update_product_status +
          "/" +
          this.productID +
          "/" +
          this.statusFlag
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.statusChangecloseBtn.nativeElement.click();
          this.getProducts();
        }
      });
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}
