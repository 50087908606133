import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { PennService } from "../common/penn.service";
import { HttpService } from "../common/services/http.service";
import { RestApi } from "../common/constants/RestAPI";
import { ActivatedRoute, Router } from "@angular/router";
import { SiteModuleList } from "../common/models/configuration.model";
import { defaultLogo, svg, url } from "../common/constants/constant";
import { DomSanitizer } from "@angular/platform-browser";
import { Roles } from "../common/constants/enums/instructionEnums";
import { UserProfile } from "../common/models/user.model";
import { SessionVariable } from "../common/class/storageLabel";
import { AddVerificationSensor } from "../common/models/sensorupload.model";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { TranslateService } from "../common/services/translate.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  @ViewChild("closeBtn", { static: true }) closeBtn: ElementRef;
  @ViewChild("uploadFile", { static: false }) uploadFile: ElementRef;
  userProfile: UserProfile;
  viewAccess: boolean = true;
  siteAccess: boolean = false;
  isFieldEngineer: boolean = false;
  isDashboardAccess: boolean = false;
  isClientUser: boolean = false;
  managersignOffAcess: boolean = false;
  templateAccess: boolean = false;
  clientID;
  siteID;
  clientName;
  siteName;
  clientPrefFlag: string;
  taskMgmtFlag: any;
  communicationMgmtFlag: string;
  sitePrefFlag: string;
  moduleListFlag: boolean = false;
  modulelogList: SiteModuleList[];
  menuOpen: boolean = false;
  logoImage: any;
  areaManager: boolean;
  taskSiteAccess: boolean;
  regionalManagerAccess: boolean;
  documentMgmtFlag: boolean;
  showOrHideSubscriptionMenu;
  file: any;
  private addVerificationSensor: AddVerificationSensor;
  tab;
  sitename;
  premiseid;
  clientid;
  statename;
  city;
  timestamp;

  constructor(
    public pennService: PennService,
    public httpService: HttpService,
    private _sanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public router: Router,
    public activatedroute: ActivatedRoute
  ) {}
  ngOnInit() {
    //this.clientID = this.pennService.getStoredData("clientID");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.activatedroute.params.subscribe((params) => {
      this.clientID = params.clientid ? params.clientid : params.id;
      this.siteID = params.siteId ? params.siteId : params.id;

      if (
        this.userProfile.roleID == Roles.SiteManager ||
        this.userProfile.roleID == Roles.AreaManager
      ) {
        this.siteID = this.pennService.getStoredData("siteID");
        this.clientID = this.pennService.getStoredData("clientID");
      }

      //used in cabinet page
      this.tab = params.tab;
      this.sitename = params.sitename;
      this.premiseid = params.premiseid;
      this.clientid = params.clientid;
      this.statename = params.statename;
      this.city = params.city;
      this.timestamp = params.timestamp;

      console.log("this.clientID1", this.clientID, "this.siteID1", this.siteID);
    });

    //getting params from child route
    if (
      this.activatedroute.firstChild &&
      (this.activatedroute.firstChild.snapshot.paramMap.get("id") ||
        this.activatedroute.firstChild.snapshot.paramMap.get("clientid"))
    ) {
      this.clientID = this.activatedroute.firstChild.snapshot.paramMap.get(
        "clientid"
      )
        ? this.activatedroute.firstChild.snapshot.paramMap.get("clientid")
        : this.activatedroute.firstChild.snapshot.paramMap.get("id");
      this.siteID = this.activatedroute.firstChild.snapshot.paramMap.get(
        "siteId"
      )
        ? this.activatedroute.firstChild.snapshot.paramMap.get("siteId")
        : this.activatedroute.firstChild.snapshot.paramMap.get("id");
      console.log("this.clientID2", this.clientID, "this.siteID2", this.siteID);
    }

    let clientData = this.pennService.getStoredObj("userClientsMapping");
    if (this.userProfile.roleName == "Site Manager") {
      this.siteID = this.userProfile.siteID;
      this.getManagerSignOffAccess(this.userProfile.siteID);
      this.moduleListFlag == true;
      this.getAllSiteModule();
    } else {
      this.managersignOffAcess = false;
    }

    this.isDashboardAccess =
      this.userProfile.roleName == "Client Admin" ||
      this.userProfile.roleName == "Regional Manager" ||
      this.userProfile.roleName == "Site Manager" ||
      this.userProfile.roleName == "Field Engineer"
        ? true
        : false;

    this.isClientUser =
      this.userProfile.roleName == "Client Admin" ||
      this.userProfile.roleName == "Regional Manager" ||
      this.userProfile.roleName == "Site Manager" ||
      this.userProfile.roleName == "Field Engineer"
        ? true
        : false;

    this.viewAccess =
      this.userProfile.roleName == "Admin Reseller" ||
      this.userProfile.roleName == "Super Admin" ||
      this.userProfile.roleName == "Client Admin"
        ? true
        : false;

    this.templateAccess =
      this.userProfile.roleName == "Admin Reseller" ||
      this.userProfile.roleName == "Super Admin";

    this.siteAccess =
      this.userProfile.roleName == "Regional Manager" ||
      this.userProfile.roleName == "Site Manager"
        ? true
        : false;
    this.taskSiteAccess =
      this.userProfile.roleName == "Site Manager" ? true : false;
    this.regionalManagerAccess =
      this.userProfile.roleName == "Regional Manager" ? true : false;

    this.addVerificationSensor = <AddVerificationSensor>{};
    this.isFieldEngineer =
      this.userProfile.roleName == "Field Engineer" ? true : false;

    this.clientName = this.pennService.getStoredData("clientName");
    this.siteName = this.pennService.getStoredData("siteName");
    this.clientPrefFlag = this.storage.get("clientPrefFlag");
    this.sitePrefFlag = this.storage.get("sitePreferenceFlag");
    this.taskManagementEnableDisableFlag();

    this.areaManager =
      this.userProfile.roleID == Roles.AreaManager ? true : false;
    //this.getUserType();
    this.showLogo(this.pennService.getLogo());
  }

  taskManagementEnableDisableFlag() {
    if (this.pennService.getStoredData("subscriptionsMenu")) {
      this.showOrHideSubscriptionMenu = JSON.parse(
        this.pennService.getStoredData("subscriptionsMenu")
      );
    }

    if (this.templateAccess || this.userProfile.roleName === "Field Engineer") {
      this.pennService.saveDataToStorage(
        "subscriptionsMenu",
        JSON.stringify({
          IsTaskManagementEnabled: false,
          IsAnnouncementManagementEnabled: false,
          IsDocumentManagementEnabled: false,
          IsRefrigerationWidgetEnabled: false,
        })
      );
    }
  }

  closeSiteSubMenu(): void {
    this.pennService.hideSiteSubNav();
  }
  showSiteSubMenu(): void {
    this.pennService.showSiteSubNav();
  }

  getUserType(): void {
    let userProfile: any = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    if (
      userProfile.roleID != Roles.AdminReseller &&
      userProfile.roleID != Roles.SuperAdmin &&
      userProfile.roleID != Roles.FieldEngineer &&
      !this.logoImage
    ) {
      this.getUserTheme(userProfile.clientID);
    } else {
      this.pennService.setLogo(defaultLogo);
      this.showLogo(defaultLogo);
    }
  }

  public getUserTheme(clientId): void {
    this.httpService
      .get(RestApi.get_client_theme + "/" + clientId)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          if (res.Data.Logo === null) {
            this.showLogo(defaultLogo);
          } else {
            this.showLogo(res.Data.Logo);
            this.pennService.setLogo(res.Data.Logo);
          }
          this.changeTheme(res.Data.PrimaryColor);
        }
      });
  }

  getSantizeUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  showLogo(baseEncode: string): void {
    this.logoImage = this._sanitizer.bypassSecurityTrustUrl(baseEncode);
  }

  changeTheme(primary: string): void {
    let secondary = this.getSecondaryColor(primary, 0.085);
    document.documentElement.style.setProperty("--jci-blue", primary);
    document.documentElement.style.setProperty("--jci-light-blue", secondary);
  }

  getSecondaryColor(hex: string, lum: any): any {
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;
    let rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }

  getManagerSignOffAccess(id) {
    this.httpService
      .get(RestApi.site_details + "/" + id)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.managersignOffAcess = res.Data.IsSignedOffEnabled;
        }
      });
  }

  logout() {
    this.closeBtn.nativeElement.click();
  }

  setClient() {
    this.storage.set("routename", "client");
    this.pennService.clientConfigActive = true;
    this.pennService.siteConfigActive = false;
    console.log("inside client test");
  }
  setSite() {
    this.storage.set("routename", "site");
    this.pennService.siteConfigActive = true;
    this.pennService.clientConfigActive = false;
    this.router.navigateByUrl("").then(() => {
      this.router.navigateByUrl("/configuration/details");
    });
  }

  redirectPreference() {
    this.activatedroute.queryParams.subscribe((params) => {
      this.clientID = params.get("clientid")
        ? params.get("clientid")
        : params.get("id");
      this.clientName = params.get("name");
    });

    // this.router.navigateByUrl("").then(() => {
    //   this.router.navigateByUrl(
    //     "/configuration/details" + "/" + this.clientID + "/" + this.clientName
    //   );
    // });
    this.router
      .navigateByUrl("/addClient", { skipLocationChange: true })
      .then(() => {
        this.storage.set("clientPrefFlag", "true");
        this.router.navigate(["/addClient/" + this.clientID, this.clientName]);
      });
  }
  redirectTask() {
    this.taskMgmtFlag = this.pennService.taskMgmtActive;
    this.router
      .navigateByUrl("/TaskManagement", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/TaskManagement/" + this.clientID]);
        //this.storage.set("taskMgmtFlag", "true");
      });
  }
  redirectCommunication() {
    this.router
      .navigateByUrl("/Communication", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/Communication/" + this.clientID]);
        this.storage.set("communicationMgmtFlag", "true");
      });
  }

  redirectDocument() {
    this.documentMgmtFlag = this.pennService.documentMgmtActive;
    this.router
      .navigateByUrl("/Documents", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/Documents/" + this.clientID]);
      });
  }

  redirectSitePreference() {
    this.router
      .navigateByUrl("/addSite", { skipLocationChange: true })
      .then(() => {
        this.storage.set("sitePreferenceFlag", "true");
        this.router.navigate(["/addSite/", this.clientID, this.siteID]);
      });
  }

  private getAllSiteModule(): void {
    if (this.userProfile && this.userProfile.siteID) {
      this.httpService
        .get(
          RestApi.site_module_list + "/" + this.userProfile.siteID.toString()
        )
        .subscribe((res: any) => {
          this.modulelogList = res.Data.moduleList;
        });
    }
  }

  routePage(moduleId) {
    this.router.navigate([`/site/${this.siteID}/moduleLogDetails/${moduleId}`]);
  }

  getModuleName(name: string) {
    this.pennService.set_moduleName(name);
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  //Method is to change color of module logo since the base64 svg are use
  public getImage(logo: string, index: string) {
    logo = logo.replace(svg.base64, "");
    logo = window.atob(logo);
    logo = logo.replace(svg.currentStock, svg.changeStock);
    logo = logo.replace(/stroke="#333333"/g, svg.changeStock);
    logo = logo.replace(/fill="#333333"/g, svg.changeFill);
    logo = logo.replace(/width="28px"/g, svg.width);
    logo = logo.replace(/width="27px"/g, svg.width);
    document.getElementById(index).innerHTML = logo;
    return true;
  }

  public redirectTo(flag: boolean): void {
    if (flag) {
      window.open(url.term);
    } else {
      window.open(url.policy);
    }
  }

  onFileSelect(files: Blob[]) {
    if (files.length > 0) {
      document.getElementById("btnUploadSensors").removeAttribute("disabled");
    }
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.file = (<string>reader.result).split(",").pop();
    };
  }

  sensorsUpload() {
    this.addVerificationSensor.UserId = this.userProfile.userID;
    this.addVerificationSensor.UserName = this.userProfile.userName;
    this.addVerificationSensor.Base64FileString = this.file;
    this.addVerificationSensor.VerificationSensors = [];
    this.addVerificationSensor.CreatedByID = this.userProfile.userID;
    this.addVerificationSensor.CreatedBy = this.userProfile.userName;
    this.addVerificationSensor.CreatedOn = new Date().toISOString();
    this.addVerificationSensor.ModifiedByID = this.userProfile.userID;
    this.addVerificationSensor.ModifiedBy = this.userProfile.userName;
    this.addVerificationSensor.ModifiedOn = new Date().toISOString();

    this.httpService
      .post(RestApi.ImportVerificationSensors, this.addVerificationSensor)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.VerificationSensor
              .verificationSensorsimportedsuccessfully,
          });
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.VerificationSensor
              .verificationSensorsimportFailed,
          });
        }
      });
  }

  uploadSensor() {
    this.uploadFile.nativeElement.value = null;
    document
      .getElementById("btnUploadSensors")
      .setAttribute("disabled", "true");
    this.file = null;
  }
}
