import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Input,
  SimpleChanges,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MessageService } from "primeng/api";
import { PennService } from "../common/penn.service";
import { UserService } from "../common/services/user-service/user.service";

import { HttpService } from "../common/services/http.service";
import {
  TranslateService,
  getUserLocale,
} from "../common/services/translate.service";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { UserData } from "../common/models/user.model";
import {
  Constant,
  common,
  userlistColumn,
  languageLocaleCodes,
} from "../common/constants/constant";
import {
  PrimeDropdown,
  ApiListResponse,
  ProcessedRowError,
} from "../common/models/configuration.model";
import { Roles } from "../common/constants/enums/instructionEnums";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";
import { ExcelService } from "../common/services/excel.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppCookieService } from "../common/services/cookie.service";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("adduserrolecloseBtn", { static: true })
  adduserrolecloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("resetCloseBtn", { static: true }) resetCloseBtn: ElementRef;
  @ViewChild("selectusertemplatecloseBtn", { static: true })
  selectusertemplatecloseBtn: ElementRef;
  @ViewChild("inputFileUpload", { static: false }) inputFileUpload: ElementRef;
  @ViewChild("userTemplateModal", { static: false })
  userTemplateModal: ElementRef;
  Title = this.translate.data.User.addUser;
  modalTitle: String;
  createdDate: String;
  userID: number;
  username: String;
  userEmailId: String;
  addUser = {} as UserData;
  roleList = [];
  selectedRole = {
    id: 0,
    name: this.translate.data.moduleInstructionType.selectrole,
  };
  userList;
  userlistcols;
  userName: PrimeDropdown<string, string>[] = [];
  Email: PrimeDropdown<string, string>[] = [];
  userSelect;
  emailSelect;
  clientList = [];
  selectedClient;
  selectedClients = [];
  areaList = [];
  selectedArea;
  siteList = [];
  companyID = null;
  selectedSite;
  roleName: string;
  fieldFlags: boolean = false;
  userProfile;
  getClientAPIURL;
  oid;
  emailFlag: boolean = false;
  editUserName: boolean = false;

  oldRoleId?: number;
  oldSiteList?: any[];
  oldSiteListRegional;
  oldAreaID?: number;
  oldSiteID?: number;
  oldClientId?: number;

  isAlarmSubcriptionVisible: boolean = false;
  isAlarmSubEnable: boolean = false;
  isAlarmSubEnableOld: boolean = false;

  changePassword: boolean = false;
  confirmPassword: string;
  clientMapping: any[] = [];
  clientSites: any[] = [];
  isSecurePasswordCreationEnable: boolean = true;
  wrongPassword: boolean = false;
  selectLabel = "";
  canImportUsers = false;
  importErrors: ProcessedRowError[] = [];
  showImportErrors: boolean = false;
  showErrorValue: boolean = false;
  allRolesList = [];
  existingUserName = "";
  editUserFlag = false;
  editUserData;
  pageFlag = "Add";
  Oid: string;
  offlineNotification = false;
  smsNotification = false;
  emailNotification = false;
  languageList = languageLocaleCodes;
  selectedLanguage = {
    key: "English",
    value: "en-EN",
  };
  allowedRoleToEditAllFields = false;
  isDisabled = false;
  oldLanguageLocale;
  prefferedlanguageChange = false;
  warnMsg = "";
  alarmSubscribtionDetails;
  oldUserData;
  oldSitesDataFlag = false;
  emailTemplateBase64String = "";
  allclientList = [];
  oldMobileNo;
  level1;
  level2;
  level3;
  enableAlarmEscalation;
  showEscalationLevelMenu = false;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe,
    private router: Router,
    private userService: UserService,
    public excelService: ExcelService,
    private cookieService: AppCookieService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private activeRoute: ActivatedRoute,
    private httpClient: HttpClient,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {
    this.editUserData = this.router.getCurrentNavigation().extras.state;
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    if (this.editUserData) {
      this.pageFlag = Constant.CommandType.edit;
      this.Title = this.translate.data.User.editUser;
      this.Oid = this.editUserData.Oid;
      this.selfEditFlowRules();
      this.editUserFlag = true;
    }
  }

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    let rolesArr = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];
    if (!this.editUserFlag) {
      this.addUserFlow(rolesArr);
    }
    this.getClientBasedonRoles(rolesArr);
    this.storage.remove("routename");
    this.pennService.hideNotificationPanel();

    this.selectLabel = this.translate.data.moduleBuilder.select;

    this.GetEmailTemplate("en-EN");
  }

  private GetEmailTemplate(language) {
    this.httpClient
      .get(`../assets/email-template/en-EN_create_password.html`, {
        responseType: "text",
      })
      .subscribe(
        (res: any) => {
          this.emailTemplateBase64String = btoa(res);
        },
        (err) => {
          this.httpClient
            .get(`../assets/email-template/en-EN_create_password.html`, {
              responseType: "text",
            })
            .subscribe((res: any) => {
              this.emailTemplateBase64String = btoa(res);
            });
        }
      );
  }

  checkClientSecurePasswordDetails(clientId: string | number) {
    this.httpService
      .get<any>(RestApi.client_preference + "/" + clientId)
      .subscribe((res: any) => {
        this.isSecurePasswordCreationEnable =
          res.Data.EnableSecurePasswordCreation === true ? true : false;
      });
  }

  getClientBasedonRoles(rolesArr) {
    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
    ) {
      this.canImportUsers =
        this.userProfile.roleName == Constant.Roles.adminReseller
          ? true
          : false;
      this.getClientAPIURL =
        RestApi.get_client_mapping_by_userid + "/" + this.userProfile.userID;
      this.getAllClientList(0);
      this.addFieldEnggRole(rolesArr);
    } else if (this.userProfile.roleName === Constant.Roles.superAdmin) {
      this.getClientAPIURL = RestApi.client_list;
      this.getAllClientList(0);
      this.addFieldEnggRole(rolesArr);
    } else if (this.userProfile.roleName === Constant.Roles.clientAdmin) {
      this.getClientAPIURL =
        RestApi.client_details + "/" + this.userProfile.clientID;
      this.getAllClientList(this.userProfile.clientID);
      this.checkClientSecurePasswordDetails(this.userProfile.clientID);
    } else if (
      this.userProfile.roleName == Constant.Roles.siteManager ||
      this.userProfile.roleName == Constant.Roles.areaManager
    ) {
      this.getClientAPIURL =
        RestApi.client_details + "/" + this.userProfile.clientID;
      this.getAllClientList(this.userProfile.clientID);
    }
  }

  addUserFlow(rolesArr) {
    this.addFieldEngineer(rolesArr);
    this.roleList = rolesArr.filter((element) => {
      return this.rolesLogicAdd(element);
    });
    this.roleList.splice(0, 0, {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    });

    this.modalTitle = this.translate.data.Common.add;
    this.userID = 0;
    this.addUser.UserID = 0;
    this.roleName = "";
    this.emailFlag = false;
    this.editUserName = false;
    this.isAlarmSubEnable = false;
    this.isAlarmSubEnableOld = false;
    this.clearModal();
  }

  addFieldEngineer(rolesArr) {
    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer ||
      this.userProfile.roleName == Constant.Roles.superAdmin
    ) {
      rolesArr.push({ id: 7, name: "Field Engineer" });
    }
  }

  getAllClientList(param) {
    this.clientList = [];
    this.httpService.get(this.getClientAPIURL).subscribe((res: any) => {
      if (res.Data) {
        if (this.userProfile.roleName === Constant.Roles.clientAdmin) {
          this.clientList.push(res.Data);
        } else {
          const data = Array.isArray(res.Data) ? res.Data : [res.Data];
          this.clientList = this.sortedList(data, "ClientName");
          this.selectedClient = data.sort((first: any, next: any) =>
            first.UserName > next.UserName ? 1 : -1
          );
        }
        if (param && this.userProfile.roleID < 3) {
          let selClient = this.clientList.filter((ele) => {
            return ele.ClientID == param;
          });
          this.selectedClient = selClient[0];
        }
        if (this.pageFlag === Constant.CommandType.edit) {
          this.editUserFlow(this.editUserData);
        }
      } else {
        this.clientList = [];
      }
    });
  }

  updateTenantIdForResellerAdmin(clientId?) {
    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
    ) {
      if (!clientId) {
        clientId = this.selectedClient.ClientID;
      }
      this.getAllClientListToFilterAllClient(clientId);
    }
  }

  addFieldEnggRole(role) {
    let fieldRolePresent = false;

    role.forEach((element) => {
      if (element.name === "Field Engineer") {
        fieldRolePresent = true;
      }
    });

    if (!fieldRolePresent) {
      role.push({ id: 7, name: "Field Engineer" });
    }

    this.setroleList(role);
  }

  setroleList(rolesArr) {
    this.roleList = rolesArr.filter((element) => {
      return this.rolesLogicAdd(element);
    });
    this.roleList.splice(0, 0, {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    });
  }

  getValidationFlag(rolename: string): void {
    if (rolename == Constant.Roles.clientAdmin) {
      this.fieldFlags = this.selectedClient ? false : true;
    } else if (
      rolename == Constant.Roles.adminReseller ||
      rolename == Constant.Roles.fieldEngineer
    ) {
      this.fieldFlags = this.selectedClients.length !== 0 ? false : true;
    } else if (rolename == Constant.Roles.areaManager) {
      this.fieldFlags = this.selectedClient && this.selectedArea ? false : true;
    } else if (
      rolename == Constant.Roles.siteManager ||
      rolename == Constant.Roles.siteUser
    ) {
      this.fieldFlags = this.selectedClient || this.selectedSite ? false : true;
    } else {
      this.fieldFlags = false;
    }
  }

  editUserFlow(data) {
    // fetch user and fill additional details like mobile number
    this.oldAreaID = data.AreaID;
    this.oldSiteID = data.SiteID;
    this.oldRoleId = data.RoleID;
    this.oldClientId = data.ClientID;
    this.oldMobileNo = data.Mobile;

    this.getClientSites(data.ClientID);
    this.updateTenantIdForResellerAdmin(this.oldClientId);
    this.getSiteListByAreaID("", "", data.AreaID);
    this.getAllClientUserMappingByUserId(data.UserID);
    this.getUserDetails(data.UserID)
      .then((data: any | null) => {
        this.Oid = data.Oid;
        this.oldUserData = data;
        this.setLanguagePrefrence(data.PreferredLanguage);
        this.offlineNotification = data.OfflineNotification;
        this.isAlarmSubcriptionVisible =
          data.RoleID === 3 ||
          data.RoleID === 4 ||
          data.RoleID === 5 ||
          data.RoleID === 6
            ? true
            : false;
        this.addUser.Mobile = data && data.Mobile ? data.Mobile : null;
        if (this.isAlarmSubcriptionVisible) {
          this.getNotificationTypeStatus();

          this.userService
            .getUserAlarmSubscriptionDetails(data.UserID)
            .then((data: any | null) => {
              this.isAlarmSubEnable = data
                ? data[0].IsAlarmSubscriptionEnabled
                : false;
              if (this.isAlarmSubEnable) {
                this.getOldSitesDataForClientAdmin(this.oldClientId);
              }
              this.isAlarmSubEnableOld = this.isAlarmSubEnable;
            })
            .catch((e: any) => null);
        }
      })
      .catch((e: any) => {
        return null;
      });

    this.modalTitle = this.translate.data.Common.edit;
    this.addUser = Object.assign({}, data);
    this.userID = data.UserID;
    this.existingUserName = data.UserName;
    this.roleName = data.RoleName;
    this.setRolelist();
    this.setUserDataModel(data);
    this.setFieldsWhichCanBeEdited();

    this.fieldFlags = false;
    this.emailFlag = true;
    this.editUserName = true;
  }

  setRolelist(flag?) {
    let rolesArr = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];

    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.superAdmin ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
    ) {
      rolesArr.push({ id: 7, name: "Field Engineer" });
    }

    this.roleList = rolesArr.filter((element) => {
      return this.rolesLogicEdit(element);
    });

    this.roleList.splice(0, 0, {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    });
    this.setSelectedRole();
  }

  setSelectedRole() {
    let selRole = this.roleList.filter((ele) => {
      return ele.id == this.editUserData.RoleID;
    });

    this.selectedRole = selRole[0];
  }

  getOldSitesDataForClientAdmin(clientID) {
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.oldSiteList =
          response.IsSuccess && response.Data && response.Data != null
            ? filterSites(response.Data)
            : [];
        this.siteSubscriptionListForClientAdminRole();
      },
      (_error: any) => (this.clientSites = [])
    );
  }

  rolesLogicEdit(element) {
    if (this.userProfile.roleName == Constant.Roles.clientAdmin) {
      return element.id >= this.userProfile.roleID;
    } else if (
      this.userProfile.roleName == Constant.Roles.superAdmin ||
      this.userProfile.roleName == Constant.Roles.adminReseller
    ) {
      if (
        this.editUserData.RoleID === 3 ||
        this.editUserData.RoleID === 4 ||
        this.editUserData.RoleID === 5 ||
        this.editUserData.RoleID === 6
      ) {
        return (
          element.id === 3 ||
          element.id === 4 ||
          element.id === 5 ||
          element.id === 6
        );
      } else {
        return element.id === 7 || element.id === 2 || element.id === 1;
      }
    } else {
      return (
        element.id >= this.userProfile.roleID &&
        element.id >= this.editUserData.RoleID
      );
    }
  }

  rolesLogicAdd(element) {
    if (this.userProfile.roleID == Roles.SuperAdmin) {
      return true;
    } else if (
      this.userProfile.roleID == Roles.ClientAdmin ||
      this.userProfile.roleID == Roles.AdminReseller
    ) {
      return element.id >= this.userProfile.roleID;
    } else return element.id > this.userProfile.roleID;
  }

  setLanguagePrefrence(preferedLangLocale) {
    const languageLocale = preferedLangLocale;

    const userLanguage = this.languageList.filter((item) => {
      return languageLocale === item.value;
    });

    setTimeout(() => {
      this.selectedLanguage = userLanguage[0];
      this.oldLanguageLocale = userLanguage[0];
      this.GetEmailTemplate(this.selectedLanguage.value);
    }, 0);
  }

  getAreaListByClientID(val: string, clientId: number, areaID: number) {
    this.areaList = [];
    this.httpService
      .get(RestApi.area_list + "/" + clientId)
      .subscribe((res: any) => {
        if (res.Data) {
          if (
            this.roleName == Constant.Roles.siteManager ||
            this.roleName == Constant.Roles.siteUser
          ) {
            this.areaList = res.Data.filter((ele: { AreaName: string }) => {
              return ele.AreaName == "Default";
            });
            if (this.pageFlag === Constant.CommandType.edit) {
              this.getSiteListByAreaID(
                Constant.CommandType.edit,
                this.editUserData.SiteID,
                this.areaList[0]?.AreaID
              );
            }
            this.getSiteListByAreaID("", "", this.areaList[0]?.AreaID);
            this.getValidationFlag(this.roleName);
          } else {
            this.areaList = this.sortedList(
              res.Data.filter((ele: { AreaName: string }) => {
                return ele.AreaName != "Default";
              }),
              "AreaName"
            );
          }
          if (val === Constant.CommandType.edit) {
            const selArea = this.areaList.filter((ele) => {
              return ele.AreaID === areaID;
            });

            setTimeout(() => {
              this.selectedArea = selArea[0];
            }, 0);
          }
        } else {
          this.areaList = [];
        }
      });
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
      b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }

  getSiteListByAreaID(val, siteid, id) {
    this.siteList = [];
    this.httpService
      .get(RestApi.site_area_list_area + "/" + id)
      .subscribe((res: any) => {
        if (res.Data) {
          this.siteList = res.Data.siteList;

          if (this.oldSiteListRegional === undefined) {
            this.oldSiteListRegional = [...res.Data.siteList];
          }

          if (val == Constant.CommandType.edit) {
            let selSite = this.siteList.filter((ele) => {
              return ele.siteID == siteid;
            });
            // selected value not updating in primeng
            // dropdown so using timeout to delay
            setTimeout(() => {
              this.selectedSite = selSite[0];
            }, 0);
          }
          if (this.selectedRole.name === Constant.Roles.areaManager) {
            return this.siteList;
          }
        } else {
          this.siteList = [];
          this.oldSiteListRegional = [];
        }
      });
  }

  onSelectRole(data) {
    this.roleName = this.selectedRole.name;
    if (this.pageFlag === Constant.CommandType.add) {
      this.selectedClients = [];
      this.selectedClient = "";
      this.selectedArea = "";
      this.selectedSite = "";
    } else if (this.pageFlag === Constant.CommandType.edit) {
      if (data.value.id === 4 || data.value.id === 5 || data.id === 6) {
        this.getAreaListByClientID("", this.selectedClient.ClientID, 0);
      }
    }
    this.warnMsg = "";
    this.getValidationFlag(this.roleName);
  }

  onSelectClient() {
    if (this.selectedClient) {
      this.updateTenantIdForResellerAdmin();
      this.getAreaListByClientID("", this.selectedClient.ClientID, 0);
      this.companyID = this.selectedClient.CompanyID;
      this.getValidationFlag(this.roleName);
      this.getClientSites(this.selectedClient.ClientID);
    }
  }

  onSelectClients() {
    this.getValidationFlag(this.roleName);
  }

  onSelectArea() {
    if (this.selectedArea) {
      this.getSiteListByAreaID("", "", this.selectedArea.AreaID);
      this.getValidationFlag(this.roleName);
    }
  }

  onSelectSite() {
    if (this.selectedSite) {
      this.getValidationFlag(this.roleName);
    }
  }

  private removeClientColumn(): any[] {
    let column = [
      {
        field: userlistColumn.UserName,
        header: this.translate.data.Common.username,
      },
      {
        field: userlistColumn.EmailID,
        header: this.translate.data.Common.email,
      },
      {
        field: userlistColumn.RoleName,
        header: this.translate.data.Common.role,
      },
      {
        field: userlistColumn.FullName,
        header: this.translate.data.Common.FullName,
      },
    ];
    let clientColumn = [
      {
        field: userlistColumn.UserName,
        header: this.translate.data.Common.username,
      },
      {
        field: userlistColumn.EmailID,
        header: this.translate.data.Common.email,
      },
      {
        field: userlistColumn.RoleName,
        header: this.translate.data.Common.role,
      },
      {
        field: userlistColumn.FullName,
        header: this.translate.data.Common.FullName,
      },
      {
        field: userlistColumn.ClientName,
        header: this.translate.data.Common.ClientName,
      },
    ];
    if (this.userProfile.roleID == Roles.ClientAdmin) {
      return column;
    } else {
      return clientColumn;
    }
  }

  private clearAlarmDetails() {
    this.isAlarmSubcriptionVisible = false;
    this.isAlarmSubEnable = false;
    this.oldSiteList = undefined;
    this.oldSiteListRegional = undefined;
    this.oldAreaID = undefined;
    this.oldSiteID = undefined;
    this.oldRoleId = undefined;
  }

  setUserDataModel(data) {
    let selClient = this.clientList.filter((ele) => {
      return ele.ClientID == data.ClientID;
    });
    this.selectedClient = selClient[0];
    this.companyID = selClient[0]?.CompanyID;
    if (data.RoleName == Constant.Roles.clientAdmin) {
      this.addUser.ClientID = data.ClientID;
      //this.getAllClientList(data.ClientID);
    } else if (data.RoleName == Constant.Roles.areaManager) {
      this.addUser.ClientID = data.ClientID;
      this.addUser.AreaID = data.AreaID;
      //this.getAllClientList(data.ClientID);
      this.getAreaListByClientID(
        Constant.CommandType.edit,
        data.ClientID,
        data.AreaID
      );
    } else if (
      data.RoleName == Constant.Roles.siteManager ||
      this.roleName == Constant.Roles.siteUser
    ) {
      this.addUser.ClientID = data.ClientID;
      this.addUser.AreaID = data.AreaID;
      this.addUser.SiteID = data.SiteID;
      //this.getAllClientList(data.ClientID);
      this.getAreaListByClientID(
        Constant.CommandType.edit,
        data.ClientID,
        data.AreaID
      );
      this.getSiteListByAreaID(
        Constant.CommandType.edit,
        data.SiteID,
        data.AreaID
      );
    }
  }

  saveUser() {
    this.addUser.UserID = this.userID;
    this.addUser.RoleID = this.selectedRole.id;
    this.addUser.RoleName = this.selectedRole.name;
    this.addUser.CreatedOn = this.createdDate;
    this.addUser.CreatedBy = this.userProfile.userName;
    this.addUser.CreatedByID = this.userProfile.userID;
    this.addUser.ModifiedOn = this.createdDate;
    this.addUser.ModifiedBy = this.userProfile.userName;
    this.addUser.ModifiedByID = this.userProfile.userID;
    this.addUser.PreferredLanguage = this.selectedLanguage.value;
    this.addUser.NotificationMethod = this.getNotificationType();
    this.addUser.OfflineNotification = this.offlineNotification;
    this.addUser.IsUpdated =
      this.pageFlag === Constant.CommandType.edit
        ? this.compareOldUserInputWithNewInput()
        : false;
    this.addUser.TenantId = this.selectedClient?.TenantId;
    this.addUser.TenantName = this.selectedClient?.TenantName;
    this.addUser.EmailTemplate = this.emailTemplateBase64String;

    this.warnMsg = "";
    // if user is reseller admin or super user
    // then set it to penn company which is default company
    // for site user, set company id as null
    if (
      this.addUser.RoleID === 1 ||
      this.addUser.RoleID === 2 ||
      this.addUser.RoleID === 7
    ) {
      this.addUser.CompanyID = "7"; // PENN Company
    } else {
      this.addUser.CompanyID = this.companyID;
    }

    this.confirmPassword = "";

    if (this.roleName == Constant.Roles.clientAdmin) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = 0;
      this.addUser.SiteID = 0;
    } else if (this.roleName == Constant.Roles.areaManager) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = this.selectedArea.AreaID;
      this.addUser.SiteID = 0;
    } else if (
      this.roleName == Constant.Roles.siteManager ||
      this.roleName == Constant.Roles.siteUser
    ) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = this.areaList[0].AreaID;
      this.addUser.SiteID = this.selectedSite.siteID;
    } else {
      this.addUser.ClientID = 0;
      this.addUser.AreaID = 0;
      this.addUser.SiteID = 0;
    }

    // on new user create set send password link as pr client secure preference value
    let sentPasswordLink =
      this.addUser.UserID === 0 ? this.isSecurePasswordCreationEnable : false;

    this.isAlarmSubcriptionVisible =
      this.selectedRole.id === 3 ||
      this.selectedRole.id === 4 ||
      this.selectedRole.id === 5 ||
      this.selectedRole.id === 6
        ? true
        : false;

    this.alarmSubscribtionDetails = undefined;

    if (this.isAlarmSubcriptionVisible) {
      let unSubscribeStores = undefined;
      if (this.isAlarmSubEnableOld) {
        if (
          (this.addUser.AreaID !== this.oldAreaID && this.oldRoleId === 4) ||
          (this.addUser.RoleID === 5 && this.oldRoleId === 4) ||
          (this.addUser.RoleID === 6 && this.oldRoleId === 4)
        ) {
          unSubscribeStores = this.clientSites.filter(
            (s) =>
              this.oldSiteList.filter(
                (ss) => ss.siteID === s.SiteID && s.PremiseID > 0
              ).length > 0
          );
        }

        if (this.addUser.AreaID !== this.oldAreaID) {
          unSubscribeStores = this.filterSitesForRegionalManager(
            this.oldSiteListRegional
          );
        } else if (
          this.addUser.RoleID !== this.oldRoleId ||
          this.addUser.ClientID !== this.oldClientId
        ) {
          unSubscribeStores = this.oldSiteList;
        } else if (this.addUser.SiteID !== this.oldSiteID) {
          unSubscribeStores = this.clientSites.filter(
            (s) => s.SiteID === this.oldSiteID && s.PremiseID > 0
          );
        }
      }

      const stores =
        this.addUser.RoleID === 5 || this.addUser.RoleID === 6
          ? [this.selectedSite]
          : this.siteList;

      const filterStores = this.filterStoresBasedOnRole(stores);

      this.alarmSubscribtionDetails = {
        IsAlarmSubscriptionEnabled: this.isAlarmSubEnable,
        UnSubscribeSites: unSubscribeStores,
        SubscribeSites:
          this.isAlarmSubEnable || this.enableAlarmEscalation
            ? filterStores ?? []
            : [],
      };
    } else if (this.isAlarmSubEnable) {
      // if subscription visiable flag is disable but previous notification is enable
      // in that case we need to explicitly need to remove alarm subscriptions
      const unSubStores = this.oldSiteList;
      this.alarmSubscribtionDetails = {
        IsAlarmSubscriptionEnabled: false,
      };

      const store = this.clientSites.filter(
        (s) =>
          unSubStores.filter((ss) => ss.siteID === s.SiteID && s.PremiseID > 0)
            .length > 0
      );

      this.alarmSubscribtionDetails[
        "SubscribeSites"
      ] = this.filterStoresBasedOnRole(store);
    }

    this.userService
      .saveUser(
        this.addUser,
        sentPasswordLink,
        this.emailNotification,
        this.smsNotification,
        this.addUser.EmailID,
        this.addUser.Mobile,
        this.offlineNotification,
        this.userProfile.userID,
        this.userProfile.roleID,
        this.addUser.UserName,
        this.isAlarmSubEnable,
        this.getEscaltionLevels().length > 0 ? true : false,
        this.getEscaltionLevels(),
        this.editUserData?.selfEdit
      )
      .then((res: any) => {
        if (res.IsSuccess) {
          if (this.selectedClients.length > 0) {
            this.mapClientsToUser();
          }
          let msg =
            this.userID > 0
              ? this.translate.data.Popuop_Msg.userupdatedsuccessfully
              : this.translate.data.Popuop_Msg.useraddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.refreshPageIfUserLanguageChanged();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      })
      .catch((_e) => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.somethingwentwrong,
        });
      });
  }

  allowedRolesForNotification() {
    return (
      this.selectedRole &&
      (this.selectedRole.id === 3 ||
        this.selectedRole.id === 4 ||
        this.selectedRole.id === 5 ||
        this.selectedRole.id === 6)
    );
  }

  checkifUserOtherSettingsMenuChanged() {
    return (
      this.isAlarmSubEnable !== this.isAlarmSubEnableOld ||
      this.oldRoleId !== this.addUser.RoleID ||
      this.addUser.AreaID !== this.oldAreaID ||
      this.addUser.SiteID !== this.oldSiteID ||
      this.addUser.ClientID !== this.oldClientId ||
      this.addUser.Mobile !== this.oldMobileNo ||
      this.addUser.PreferredLanguage !== this.oldLanguageLocale.value
    );
  }

  getEscaltionLevels() {
    if (this.enableAlarmEscalation) {
      let escalationLevelArr = [];

      if (this.level1) {
        escalationLevelArr.push(60);
      }
      if (this.level2) {
        escalationLevelArr.push(70);
      }
      if (this.level3) {
        escalationLevelArr.push(80);
      }
      return escalationLevelArr;
    } else {
      return [];
    }
  }

  compareOldUserInputWithNewInput() {
    let editFlag = false;
    for (const oldkeys in this.oldUserData) {
      for (const newkeys in this.addUser) {
        if (
          this.fieldsNotUsedToMatch(newkeys) &&
          newkeys === oldkeys &&
          !editFlag
        ) {
          if (
            this.oldUserData[`${oldkeys}`] &&
            this.addUser[`${newkeys}`] &&
            this.addUser[`${newkeys}`] !== this.oldUserData[`${oldkeys}`]
          ) {
            editFlag = true;
          }
        }
      }
    }
    return editFlag;
  }

  filterStoresBasedOnRole(stores) {
    if (this.selectedRole.name === Constant.Roles.clientAdmin) {
      return this.clientSites;
    } else if (this.selectedRole.name === Constant.Roles.areaManager) {
      return this.filterSitesForRegionalManager(this.siteList);
    } else {
      return this.clientSites.filter(
        (s) =>
          stores.filter((ss) => ss.siteID === s.SiteID && s.PremiseID > 0)
            .length > 0
      );
    }
  }

  filterSitesForRegionalManager(sitesBasedonArea) {
    let sites = [];
    sitesBasedonArea.forEach((siteOnArea) => {
      this.clientSites.forEach((clientsites) => {
        if (clientsites.SiteID === siteOnArea.siteID) {
          sites.push({
            SiteID: clientsites.SiteID,
            PremiseID: clientsites.PremiseID,
          });
        }
      });
    });
    return sites;
  }

  disableSubscriptionUiState() {
    this.isAlarmSubEnable = false;
    this.offlineNotification = false;
  }

  fieldsNotUsedToMatch(key) {
    return (
      key !== "CreatedOn" &&
      key !== "CreatedBy" &&
      key !== "CreatedByID" &&
      key !== "ModifiedOn" &&
      key !== "ModifiedBy" &&
      key !== "ModifiedByID"
    );
  }

  disableSmsEmailNotification() {
    this.emailNotification = false;
    this.smsNotification = false;
  }

  mapClientsToUser() {
    if (this.userID === 0) {
      this.httpService
        .get(
          RestApi.user_detail_by_username +
            `/?userName=${this.addUser.UserName}`
        )
        .subscribe((res: any) => {
          if (res.Data) {
            const oid = res.Data["Oid"];
            const data = {
              ClientID: this.selectedClients[0],
              UserID: res.Data["UserID"],
              UserName: res.Data["UserName"],
              CreatedOn: this.createdDate,
              CreatedBy: this.userProfile.userName,
              CreatedByID: this.userProfile.userID,
              ModifiedOn: this.createdDate,
              ModifiedBy: this.userProfile.userName,
              ModifiedByID: this.userProfile.userID,
              Clients: this.selectedClients,
            };
            this.mapManagedCompaniesToUser(oid);
            this.addOrUpdateMapClientToUser(data);
          }
        });
    } else {
      const data = {
        ClientID: this.selectedClients[0],
        UserID: this.userID,
        UserName: this.existingUserName,
        CreatedOn: this.createdDate,
        CreatedBy: this.userProfile.userName,
        CreatedByID: this.userProfile.userID,
        ModifiedOn: this.createdDate,
        ModifiedBy: this.userProfile.userName,
        ModifiedByID: this.userProfile.userID,
        Clients: this.selectedClients,
      };
      this.mapManagedCompaniesToUser(this.Oid);
      this.addOrUpdateMapClientToUser(data);
    }
  }

  private addOrUpdateMapClientToUser(data: {
    ClientID: any;
    UserID: any;
    UserName: any;
    CreatedOn: String;
    CreatedBy: any;
    CreatedByID: any;
    ModifiedOn: String;
    ModifiedBy: any;
    ModifiedByID: any;
    Clients: any[];
  }) {
    this.httpService
      .post(RestApi.map_client_user, data)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          try {
            let userInfo: object | null = {
              UserID: data.UserID,
              UserName: data.UserName,
              Role: this.selectedRole.id,
              ClientID: 0,
              AreaID: 0,
              SiteID: 0,
              IsUpdated: this.getUpdateFlag(),
            };
            const resRFPremisesAcess = this.httpService
              .post(RestApi.update_rf_premise_access, userInfo)
              .toPromise<any>();
            this.routeToUserListPage();
          } catch (err) {}
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  unMapClientsToUser() {
    const data = {
      ClientID: 0,
      UserID: this.userID,
      UserName: this.username,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: this.userProfile.userID,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: this.userProfile.userID,
      Clients: this.selectedClients,
    };

    this.httpService
      .post(RestApi.unmap_client_user, data)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.deletecloseBtn.nativeElement.click();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  mapManagedCompaniesToUser(oid) {
    let companyIds = [];
    let res = [];
    res = this.clientList?.filter((el) => {
      return this.selectedClients?.find((element) => {
        return element === el.ClientID;
      });
    });

    companyIds = res?.map(function (a) {
      return a.CompanyID;
    });
    let obj = {
      Oid: oid,
      CompanyIds: companyIds,
      IsAdded: this.userID === 0 ? true : false,
    };

    this.httpService
      .post(RestApi.map_managed_company_user, obj)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          if (
            this.userProfile.userID !== this.addUser.UserID &&
            this.addUser.RoleID <= 3
          ) {
            this.router.navigate(["/user"]);
          }
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: response.ReturnMessage,
          });
        }
      });
  }

  getNotificationType() {
    if (this.emailNotification && this.smsNotification) {
      return "BOTH";
    } else if (this.emailNotification) {
      return "EMAIL";
    } else if (this.smsNotification) {
      return "SMS";
    } else {
      return "NoEmailNoSMS";
    }
  }

  refreshPageIfUserLanguageChanged() {
    if (
      this.oldLanguageLocale &&
      this.selectedLanguage.value !== this.oldLanguageLocale.value &&
      this.addUser.UserID === this.userProfile.userID
    ) {
      this.prefferedlanguageChange = true;
    }

    if (this.editUserData && this.editUserData.selfEdit) {
      this.addUser["UserID"] = this.userProfile.userID;
      this.saveSubscrition();
    } else {
      this.getUsers();
    }
  }

  getUsers() {
    this.httpService
      .get(RestApi.all_user_list)
      .subscribe((res: ApiListResponse<UserData>) => {
        if (res.IsSuccess) {
          this.userList = res.Data;
          if (this.userList && this.userList.length > 0) {
            let recentlyaddedOrLowerRoleUserDetails = this.userList.filter(
              (ele) =>
                ele.UserName.toLowerCase() ===
                this.addUser.UserName.toLowerCase()
            );

            this.setCurrentUserIdsetCurrentUserId(
              recentlyaddedOrLowerRoleUserDetails
            );
          }

          this.saveSubscrition();
        }
      });
  }

  setCurrentUserIdsetCurrentUserId(recentlyaddedOrLowerRoleUserDetails) {
    if (this.editUserData && this.editUserData.selfEdit) {
      this.addUser["UserID"] = this.userProfile.userID;
    } else {
      this.addUser["UserID"] = recentlyaddedOrLowerRoleUserDetails[0].UserID;
    }
  }

  async saveSubscrition() {
    if (this.addUser !== null && this.alarmSubscribtionDetails !== undefined) {
      try {
        if (this.alarmSubscribtionDetails["UnSubscribeSites"] !== undefined) {
          // unsubscribe previous sites subscriptions
          const data = {
            UserID: this.addUser["UserID"],
            UserName: this.addUser["UserName"],
            IsAlarmSubscriptionEnabled: false,
            SubscribeSites: this.alarmSubscribtionDetails[
              "UnSubscribeSites"
            ].map((s) => ({ SiteID: s.SiteID, PremiseID: s.PremiseID })),
            CreatedBy: this.addUser["CreatedBy"],
            CreatedByID: this.addUser["CreatedByID"],
            CreatedOn: new Date().toISOString(),
            IsEscalationEnabled:
              this.getEscaltionLevels().length > 0 ? true : false,
            EscalationLevels: this.getEscaltionLevels(),
          };
          try {
            await this.httpService
              .post(RestApi.save_user_alarm_subcription, data)
              .toPromise<any>();
          } catch (err) {
            return null;
          }
        }

        const data = {
          UserID: this.addUser["UserID"],
          UserName: this.addUser["UserName"],
          IsAlarmSubscriptionEnabled: this.alarmSubscribtionDetails[
            "IsAlarmSubscriptionEnabled"
          ],
          SubscribeSites: this.alarmSubscribtionDetails[
            "SubscribeSites"
          ].map((s) => ({ SiteID: s.SiteID, PremiseID: s.PremiseID })),
          CreatedBy: this.addUser["CreatedBy"],
          CreatedByID: this.addUser["CreatedByID"],
          CreatedOn: new Date().toISOString(),
          IsEscalationEnabled:
            this.getEscaltionLevels().length > 0 ? true : false,
          EscalationLevels: this.getEscaltionLevels(),
        };

        try {
          await this.httpService
            .post(RestApi.save_user_alarm_subcription, data)
            .toPromise<any>();
          this.routeToUserListPage();
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.routeToUserListPage();
    }
  }

  getNotificationTypeStatus() {
    this.httpService
      .get(RestApi.GetUserNotificationMethod + `/${this.addUser.EmailID}`)
      .subscribe((response: any) => {
        let result = response.Data;
        if (result) {
          if (result.notificationMethod === "EMAIL") {
            this.emailNotification = true;
          } else if (result.notificationMethod === "SMS") {
            this.smsNotification = true;
          } else if (result.notificationMethod === "BOTH") {
            this.emailNotification = true;
            this.smsNotification = true;
          } else {
            this.emailNotification = false;
            this.smsNotification = false;
          }
        }
        this.setEscalationLevelMenu(result);
      });
  }

  setEscalationLevelMenu(data) {
    this.enableAlarmEscalation = data.isEscalationEnabled ? true : false;
    if (data.escalationLevels) {
      this.showEscalationLevelMenu = true;
      let levelsArr = data.escalationLevels;
      this.level1 = levelsArr.includes(60);
      this.level2 = levelsArr.includes(70);
      this.level3 = levelsArr.includes(80);
    }
  }

  private async getUserDetails(userId: number): Promise<null | any> {
    let response = await this.httpService
      .get(RestApi.user_details + "/" + userId)
      .toPromise<any>();

    if (response.IsSuccess === false || response.Data === null) {
      return null;
    }
    return response.Data;
  }

  public clearModal(): void {
    this.addUser = <UserData>{};
    this.selectedRole = {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    };
    this.confirmPassword = "";
    this.changePassword = false;
    this.companyID = null;
    this.selectedClient = null;
  }

  public isPasswordInvalid(): boolean {
    if (this.addUser.Password) {
      return !this.addUser.Password.match(
        "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      );
    }

    return false;
  }

  public isMobileInvalid(): boolean {
    if (this.addUser.Mobile) {
      let regexp = /^\+[0-9]{8,14}$/;
      return regexp.test(this.addUser.Mobile) === false;
    }

    return false;
  }

  public isEmailInvalid(): boolean {
    if (this.addUser.EmailID) {
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return regexp.test(this.addUser.EmailID) === false;
    }

    return false;
  }

  public checkifEmailIdHaveValidDomain() {
    if (
      this.addUser.EmailID &&
      this.selectedRole &&
      this.selectedRole.id === 1
    ) {
      const regexp = /^.*@jci\.com|.*@shoppertrak\.com$/;
      return !regexp.test(this.addUser.EmailID);
    }

    return false;
  }

  ValidateUser(): boolean {
    if (this.userID == 0) {
      let status =
        !this.addUser.FullName ||
        !this.addUser.UserName ||
        this.isEmailInvalid() ||
        this.checkifEmailIdHaveValidDomain() ||
        this.isMobileInvalid() ||
        this.selectedRole.id == 0 ||
        this.fieldFlags;

      if (this.isSecurePasswordCreationEnable === false) {
        status =
          status ||
          this.isPasswordInvalid() ||
          !this.addUser.Password ||
          this.addUser.Password != this.confirmPassword;
      }

      return status;
    } else
      return (
        !this.addUser.FullName ||
        !this.addUser.UserName ||
        !this.addUser.EmailID ||
        this.selectedRole?.id == 0 ||
        this.fieldFlags ||
        this.isMobileInvalid() ||
        this.checkifEmailIdHaveValidDomain() ||
        (this.changePassword == true
          ? !this.addUser.Password ||
            this.isPasswordInvalid() ||
            this.addUser.Password != this.confirmPassword
          : false)
      );
  }

  alarmSubscriptionStatus() {
    if (this.isAlarmSubEnable) {
      if (this.smsNotification || this.emailNotification) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  disabledState() {
    const status = this.ValidateUser();
    return status;
  }

  validateUserRole() {
    if (
      (this.userProfile &&
        this.userProfile.roleName === Constant.Roles.adminReseller) ||
      this.userProfile.roleName == Constant.Roles.superAdmin
    ) {
      return (
        this.selectedRole?.id === 0 ||
        this.selectedClient === null ||
        this.selectedClient === ""
      );
    } else {
      return this.selectedRole?.id === 0;
    }
  }

  checkEnableSubscriptionOfflineNotificationState() {
    if (!this.smsNotification && !this.emailNotification) {
      this.disableSubscriptionUiState();
    }
  }

  selfEditFlowRules() {
    if (this.editUserData.selfEdit) {
      this.isDisabled = true;
    }
  }

  getUpdateFlag() {
    if (this.pageFlag === Constant.CommandType.add) {
      return false;
    } else if (this.pageFlag === Constant.CommandType.edit) {
      return true;
    }
  }

  logout() {
    const userId = this.userProfile.userName;
    this.cookieService.deleteFromCookie("currentUser");
    this.storage.clear();
    this.sessionStorage.clear();

    const url =
      environment.commonAuthUrl +
      "/v1/api/cookie/delete" +
      "?userid=" +
      userId +
      "&redirecturl=" +
      location.origin;
    window.location.href = url;
  }

  onSelectLanguage() {
    this.emailTemplateBase64String = "";
    this.GetEmailTemplate(this.selectedLanguage.value);
  }

  getAllClientListToFilterAllClient(param) {
    this.allclientList = [];
    const url = RestApi.client_details + "/" + param;
    this.httpService.get(url).subscribe((res: any) => {
      if (res.Data) {
        this.allclientList = Array.isArray(res.Data) ? res.Data : [res.Data];
      }
      if (
        this.selectedClient &&
        this.selectedClient.hasOwnProperty("TenantId") &&
        this.selectedClient.hasOwnProperty("TenantName")
      ) {
        this.selectedClient["TenantId"] = this.allclientList[0].TenantId;
        this.selectedClient["TenantName"] = this.allclientList[0].TenantName;
      }
    });
  }

  checkEnableLevelState() {}

  setFieldsWhichCanBeEdited() {
    if (
      this.userProfile.roleName === Constant.Roles.superAdmin ||
      this.userProfile.roleName === Constant.Roles.adminReseller
    ) {
      if (
        this.roleName === Constant.Roles.superAdmin ||
        this.roleName === Constant.Roles.adminReseller ||
        this.roleName === Constant.Roles.fieldEngineer
      ) {
        this.isDisabled = true;
      }
    } else if (this.userProfile.roleName === Constant.Roles.clientAdmin) {
      if (
        this.roleName === Constant.Roles.superAdmin ||
        this.roleName === Constant.Roles.adminReseller ||
        this.roleName === Constant.Roles.clientAdmin ||
        this.roleName === Constant.Roles.fieldEngineer
      ) {
        this.isDisabled = true;
      }
    }
  }

  routeToUserListPage() {
    if (
      this.userProfile.userID !== this.addUser.UserID &&
      this.addUser.RoleID <= 3
    ) {
      this.router.navigate(["/user"]);
    }
  }

  public checkPassword(): void {
    if (this.addUser.Password == this.confirmPassword) {
      this.wrongPassword = false;
    } else {
      this.wrongPassword = true;
    }
  }

  public togglePasswordChange(): void {
    if (!this.changePassword) {
      this.addUser.Password = this.confirmPassword = "";
    }
  }

  siteSubscriptionListForClientAdminRole() {
    if (this.selectedRole?.name === Constant.Roles.clientAdmin) {
      return this.clientSites;
    }
  }

  toggleEnableAlarmEscalation() {
    if (this.enableAlarmEscalation) {
      this.showEscalationLevelMenu = true;
    } else {
      this.resetEscalationLevelMenu();
    }
  }

  resetEscalationLevelMenu() {
    this.showEscalationLevelMenu = false;
    this.level1 = this.level2 = this.level3 = false;
  }

  private getClientSites(clientID) {
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.clientSites =
          response.IsSuccess && response.Data && response.Data != null
            ? filterSites(response.Data)
            : [];
        this.siteSubscriptionListForClientAdminRole();
      },
      (_error: any) => (this.clientSites = [])
    );
  }

  private getAllClientUserMappingByUserId(userID) {
    this.httpService
      .get(`${RestApi.get_client_user_mapping_by_userid}/${userID}`)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          this.selectedClients = [];
          let res = [];
          res = this.clientList?.filter((el) => {
            return response.Data?.find((element) => {
              return element.ClientID === el.ClientID;
            });
          });
          this.selectedClients = res?.map(function (a) {
            return a.ClientID;
          });
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: response.ReturnMessage,
          });
        }
      });
  }
}

const filterSites = (sites) => sites.filter((site) => site.Status === "Active");
