import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { DatePipe } from "@angular/common";
import { Constant } from "../common/constants/constant";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { RestApi } from "../common/constants/RestAPI";
import { MessageService } from "primeng/api";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { TranslateService } from "../common/services/translate.service";

export enum Page {
  SensorPage = "sensor",
  GatewayPage = "gateway",
  GenericGatewaySensorPage = "generic",
}
@Component({
  selector: "app-enter-serial-number",
  templateUrl: "./enter-serial-number.component.html",
  styleUrls: ["./enter-serial-number.component.scss"],
})
export class EnterSerialNumberComponent implements OnInit {
  s;
  public myForm: FormGroup;
  clientId: number;
  clientName: string;
  siteName: string;
  siteID: number;
  premiseID: number;
  status: string;
  createdDate;
  userProfile;
  routeType;
  oldGatewayKey: string;
  oldSensorKey: string;
  replaceGatewayKey: boolean = false;
  replaceSensorKey: boolean = false;
  sensorGatewayKey: string;
  gatewayID: string;
  cabinetId: number;
  cabinetName: string = "";
  sensorTemplateID: number;
  sensorTemplate: any;
  sensorTemplateName: string = "";
  isCustomSensorTemplate: boolean = false;
  replaceSensorType: string;
  deviceType;
  errorMsgSerialNoLength = "";

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe,
    public messageService: MessageService,
    private router: Router,
    private translate: TranslateService,
    private activeroute: ActivatedRoute
  ) {
    const routingInfo = this.router.getCurrentNavigation();
    this.deviceType = this.activeroute.snapshot.params["deviceType"];
    if (routingInfo && routingInfo.extras.state) {
      this.routeType = this.router.getCurrentNavigation().extras.state.devicetype;
    } else {
      this.routeToListGatewayDevicePage();
    }
  }

  ngOnInit(): void {
    this.siteID = this.pennService.getStoredData("siteID");
    this.clientId = this.pennService.getStoredData("clientID");
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.clientName = this.pennService.getStoredData("clientName");
    this.replaceGatewayKey = this.pennService.getStoredData(
      "replaceGatewayKey"
    );
    this.replaceSensorKey = JSON.parse(
      this.pennService.getStoredData("replaceSensorKey")
    );

    this.sensorGatewayKey = this.pennService.getStoredData("sensorGatewayKey");
    this.oldGatewayKey = this.pennService.getStoredData("gatewayKey");
    this.oldSensorKey = this.pennService.getStoredData("sensorKey");
    this.gatewayID = this.pennService.getStoredData("gatewayID");
    this.cabinetId = this.pennService.getStoredData("cabinetId");
    this.sensorTemplateID = parseInt(
      this.pennService.getStoredData("sensorTemplateID")
    );
    this.cabinetName = this.pennService.getStoredData("cabinetName");
    this.siteName = this.pennService.getStoredData("siteName");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.premiseID = this.pennService.getStoredData("premiseID");
    this.replaceSensorType = this.pennService.getStoredData(
      "replaceSensorType"
    );
    this.status = this.pennService.getStoredData("status");
    this.myForm = this.formBuilder.group({
      serialNumber: ["", Validators.required],
    });
  }
  get serialNumber() {
    return this.myForm.get("serialNumber");
  }

  isFormValid() {
    return this.myForm.valid;
  }

  isSameSensorType(res: any): boolean {
    return (
      res &&
      res.Data &&
      res.Data.sensorDeviceModel &&
      (res.Data.sensorDeviceModel.SensorType === this.replaceSensorType ||
        (this.replaceSensorType === "Pipe Monitoring" &&
          res.Data.sensorDeviceModel.SensorType === "Temperature"))
    );
  }

  onSubmit() {
    if (this.isFormValid()) {
      const serialNumber: string = this.serialNumber.value;
      let payload;
      let url;
      if (this.routeType === Page.GatewayPage) {
        if (this.deviceType === "generic") {
          url = RestApi.ValidateGatewayDevice + "?validateDevice=false";
        } else {
          url = RestApi.ValidateGatewayDevice;
        }

        payload = {
          GatewayKey: serialNumber.toLowerCase(),
          ClientID: this.clientId,
          ClientName: this.clientName,
          SiteID: this.siteID,
          SiteName: this.siteName,
          PremiseID: this.premiseID,
          Status: this.status,
          Enabled: 1,
          CreatedOn: this.createdDate,
          CreatedByID: this.userProfile.userID,
          CreatedBy: this.userProfile.userName,
          ModifiedOn: this.createdDate,
          ModifiedByID: this.userProfile.userID,
          ModifiedBy: this.userProfile.userName,
        };
      } else if (this.routeType === Page.SensorPage) {
        if (this.deviceType === "generic") {
          url = RestApi.ValidateSensorDevice + "?validateDevice=false";
        } else {
          url = RestApi.ValidateSensorDevice;
        }

        payload = {
          SensorKey: serialNumber.toUpperCase(),
          ClientID: this.clientId,
          ClientName: this.clientName,
          SiteID: this.siteID,
          SiteName: this.siteName,
          PremiseID: this.premiseID,
          Status: this.status,
          Enabled: 1,
          CreatedOn: this.createdDate,
          CreatedByID: this.userProfile.userID,
          CreatedBy: this.userProfile.userName,
          ModifiedOn: this.createdDate,
          ModifiedByID: this.userProfile.userID,
          ModifiedBy: this.userProfile.userName,
        };
      }

      this.httpService.post(url, payload).subscribe((res) => {
        if (res.IsSuccess) {
          if (this.routeType === Page.GatewayPage) {
            const navigationExtras: NavigationExtras = {
              state: {
                payload: payload,
                oldGatewayKey: this.oldGatewayKey,
                newGatewayKey: serialNumber.toLowerCase(),
                gatewayKey: res.Data.GatewayKey,
                signalStrength: res.Data.SignalStrength,
                ipAddress: res.Data.IPAddress,
                replaceGatewayKey: this.replaceGatewayKey,
              },
            };
            if (this.deviceType === "generic") {
              this.router.navigate(
                ["/commissioning/saveGateway", this.deviceType],
                navigationExtras
              );
            } else {
              this.router.navigate(
                ["/commissioning/saveGateway"],
                navigationExtras
              );
            }
          } else if (this.routeType === Page.SensorPage) {
            if (!this.replaceSensorKey || this.isSameSensorType(res)) {
              if (res && res.Data && res.Data.sensorTemplates) {
                const sensorTemplates = res.Data.sensorTemplates;
                for (const template of sensorTemplates) {
                  if (template.SensorTemplateID === this.sensorTemplateID) {
                    this.sensorTemplateName = template.Name;
                    this.isCustomSensorTemplate =
                      template.IsCustomSensorTemplate;
                    break;
                  }
                }
              }
              const commissionedSensorDetails = {
                state: {
                  sensorDetails: res.Data,
                  oldSensorKey: this.oldSensorKey,
                  replaceSensorKey: this.replaceSensorKey,
                  sensorGatewayKey: this.sensorGatewayKey,
                  gatewayID: this.gatewayID,
                  cabinetId: this.cabinetId,
                  cabinetName: this.cabinetName,
                  sensorTemplateName: this.sensorTemplateName,
                  sensorTemplateID: this.sensorTemplateID,
                  isCustomSensorTemplate: this.isCustomSensorTemplate,
                },
              };

              if (this.deviceType === "generic") {
                this.router.navigate(
                  ["/commissioning/saveSensor", this.deviceType],
                  commissionedSensorDetails
                );
              } else {
                this.router.navigate(
                  ["/commissioning/saveSensor"],
                  commissionedSensorDetails
                );
              }
            } else {
              this.messageService.add({
                severity: ToastMsg.severity.error,
                summary: this.translate.data.Popuop_Msg.sensorTypeMismatch,
              });
              setTimeout(() => {
                this.routeToListGatewayDevicePage();
              }, 2500);
            }
          }
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
          setTimeout(() => {
            this.routeToListGatewayDevicePage();
          }, 2500);
        }
      });
    }
  }

  validationForPipe(event) {
    this.errorMsgSerialNoLength = "";
    if (this.deviceType === "generic") {
      const serialNo = event.target.value ?? 0;
      if (serialNo.length === 0 || serialNo.length < 16) {
        this.errorMsgSerialNoLength = this.translate.data.Commissioning.genericCommisioningKeyWarning;
        return true;
      } else {
        this.errorMsgSerialNoLength = "";
      }
    } else {
      this.errorMsgSerialNoLength = "";
      return null;
    }
  }

  routeToListGatewayDevicePage() {
    if (this.deviceType === "generic") {
      this.router.navigate([
        "/commissioning/listsensorgateway",
        this.deviceType,
      ]);
    } else {
      this.router.navigate(["/commissioning/listsensorgateway"]);
    }
  }
}
