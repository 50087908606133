import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { forkJoin } from "rxjs";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";

@Component({
  selector: "app-commtasklist",
  templateUrl: "./commtasklist.component.html",
  styleUrls: ["./commtasklist.component.scss"],
})
export class CommtasklistComponent implements OnInit {
  @ViewChild("closepStepper") closepStepper;
  @Output() loadtasklist: EventEmitter<boolean> = new EventEmitter();
  @Input() reload: boolean;
  onetimeData: any;
  dailyData: any;
  weeklyData: any;
  monthlyData: any;
  yearlyData: any;
  onetimeSelectedType: any;
  recurringSelectedType: any;
  selectedNotificationType: any;
  clientID: string;
  OpenStatusRegions: { [id: string]: boolean } = {};
  OpenStatusSite: { [id: string]: boolean } = {};
  OpenStatusRecipient: { [id: string]: boolean } = {};
  showModalComm: boolean = true;
  menuType: string;
  popupData: number;
  popupTaskData: any;
  selectedRow: any[] = [];
  userProfile: any;
  roleId: any;
  siteID: any;
  getDataUrl: string;
  dailyUrl: string;
  weeklyUrl: string;
  monthlyUrl: string;
  yearlyUrl: string;
  notificationData: any;
  statusData: any;
  reccurnItem: any;
  frequencyLsitMonthly: boolean = false;
  frequencyLsitYearly: boolean = false;
  frequencyLsitWeekly: boolean = false;
  frequencyLsitDaily: boolean = false;
  regionId: any;
  frequencyLsitOnetime: boolean = false;
  selectedIndex: any;
  currentOffset = 0;
  pageSize = 5;
  records: any;
  dailyRecords: any;
  weeklyRecords: any;
  monthlyRecords: any;
  yearlyRecords: any;
  currentPage = 1;
  onetimeDataRecord: any;
  dailyDataRecord: any;
  weeklyDataRecord: any;
  monthlyDataRecord: any;
  yearlyDataRecord: any;
  recurringSelectedTypeDaily: any;
  recurringSelectedTypeWeekly: any;
  recurringSelectedTypeMonthly: any;
  recurringSelectedTypeYearly: any;
  activeIndex: number = 0;
  checkBoxSelected: any;
  freqType: this;
  clickedStatus: { [id: string]: boolean } = {};
  selectedRowLength: number = 0;
  UpdateStatusRow: any;

  constructor(
    public announcementHttpService: HttpService,
    private datepipe: DatePipe,
    private pennservice: PennService,
    private translate: TranslateService,
    private messageService: MessageService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.onetimeSelectedType = "ALL";
    this.recurringSelectedType = "ALL";
    this.recurringSelectedTypeDaily = "ALL";
    this.recurringSelectedTypeWeekly = "ALL";
    this.recurringSelectedTypeMonthly = "ALL";
    this.recurringSelectedTypeYearly = "ALL";

    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userProfile.roleID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.siteID = this.userProfile.siteID;
    this.regionId = this.userProfile.areaID;
    this.tabChange();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["reload"]) {
      this.recurringSelectedTypeDaily = "ALL";
      this.recurringSelectedTypeWeekly = "ALL";
      this.recurringSelectedTypeMonthly = "ALL";
      this.recurringSelectedTypeYearly = "ALL";
      this.onetimeSelectedType = "ALL";
      this.recurringSelectedType = "ALL";
      this.loadOneTimeData(this.onetimeSelectedType);
    }
  }
  loadOneTimeData(selectionType: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.getDataUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=onetime&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.getDataUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=onetime&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.getDataUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=onetime&notificationType=" +
        selectionType.toUpperCase();
    }
    this.announcementHttpService.get(this.getDataUrl).subscribe(
      (data: any) => {
        if (data.frequencyTypeTaskListMap != null) {
          this.frequencyLsitOnetime = false;
          this.onetimeData = data.frequencyTypeTaskListMap.ONETIME;
          this.freqType = this.onetimeData[0].frequencyType.toLowerCase();
          this.BindTableData(this.onetimeData, this.freqType);
          this.records = this.onetimeData.length;
        } else {
          this.frequencyLsitOnetime = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
    this.checkBoxSelected = undefined;
    this.selectedRow = [];
  }
  loadRecurringDataDaily(selectionType: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=daily&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=daily&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=daily&notificationType=" +
        selectionType.toUpperCase();
    }

    const daily$ = this.announcementHttpService.get(this.dailyUrl);

    forkJoin([daily$]).subscribe(
      (response: any) => {
        if (response[0].frequencyTypeTaskListMap != null) {
          this.frequencyLsitDaily = false;
          this.dailyData = response[0].frequencyTypeTaskListMap.DAILY;
          this.freqType = this.dailyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.dailyData, this.freqType);
          this.dailyRecords = this.dailyData.length;
        } else {
          this.frequencyLsitDaily = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
    this.checkBoxSelected = undefined;
    this.selectedRow = [];
  }
  loadRecurringDataWeekly(selectionType: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.weeklyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=weekly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.weeklyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=weekly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.weeklyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=weekly&notificationType=" +
        selectionType.toUpperCase();
    }
    const weekly$ = this.announcementHttpService.get(this.weeklyUrl);
    forkJoin([weekly$]).subscribe(
      (response: any) => {
        if (response[0].frequencyTypeTaskListMap != null) {
          this.frequencyLsitWeekly = false;
          this.weeklyData = response[0].frequencyTypeTaskListMap.WEEKLY;
          this.freqType = this.weeklyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.weeklyData, this.freqType);
          this.weeklyRecords = this.weeklyData.length;
        } else {
          this.weeklyData = [];
          this.frequencyLsitWeekly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
    this.checkBoxSelected = undefined;
    this.selectedRow = [];
  }
  loadRecurringDataMonthly(selectionType: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.monthlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=monthly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.monthlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=monthly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.monthlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=monthly&notificationType=" +
        selectionType.toUpperCase();
    }
    const monthly$ = this.announcementHttpService.get(this.monthlyUrl);
    forkJoin([monthly$]).subscribe(
      (response: any) => {
        if (response[0].frequencyTypeTaskListMap != null) {
          this.frequencyLsitMonthly = false;
          this.monthlyData = response[0].frequencyTypeTaskListMap.MONTHLY;
          this.freqType = this.monthlyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.monthlyData, this.freqType);
          this.monthlyRecords = this.monthlyData.length;
        } else {
          this.monthlyData = [];
          this.frequencyLsitMonthly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
    this.checkBoxSelected = undefined;
    this.selectedRow = [];
  }
  loadRecurringDataYearly(selectionType: string) {
    if (this.roleId == Roles.ClientAdmin) {
      this.yearlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=yearly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.yearlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=yearly&notificationType=" +
        selectionType.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.yearlyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=yearly&notificationType=" +
        selectionType.toUpperCase();
    }

    const yearly$ = this.announcementHttpService.get(this.yearlyUrl);

    forkJoin([yearly$]).subscribe(
      (response: any) => {
        if (response[0].frequencyTypeTaskListMap != null) {
          this.frequencyLsitYearly = false;
          this.yearlyData = response[0].frequencyTypeTaskListMap.YEARLY;
          this.freqType = this.yearlyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.yearlyData, this.freqType);
          this.yearlyRecords = this.yearlyData.length;
        } else {
          this.yearlyData = [];
          this.frequencyLsitYearly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
    this.checkBoxSelected = undefined;
    this.selectedRow = [];
  }
  checkboxChange(item) {
    if (item.frequencyType == "ONETIME") {
      this.UpdateStatusRow = this.onetimeData.find(
        (x) => x.communicationDefID == item.communicationDefID
      );
    } else if (item.frequencyType == "DAILY") {
      this.UpdateStatusRow = this.dailyData.find(
        (x) => x.communicationDefID == item.communicationDefID
      );
    } else if (item.frequencyType == "WEEKLY") {
      this.UpdateStatusRow = this.weeklyData.find(
        (x) => x.communicationDefID == item.communicationDefID
      );
    } else if (item.frequencyType == "MONTHLY") {
      this.UpdateStatusRow = this.monthlyData.find(
        (x) => x.communicationDefID == item.communicationDefID
      );
    } else if (item.frequencyType == "YEARLY") {
      this.UpdateStatusRow = this.yearlyData.find(
        (x) => x.communicationDefID == item.communicationDefID
      );
    }
  }
  toggleCommStatus(confirm: boolean) {
    let inputObj;
    if (confirm) {
      this.UpdateStatusRow.isDisableCommunication = this.UpdateStatusRow.isDisableCommunication;
      inputObj = {
        communicationDefID: this.UpdateStatusRow.communicationDefID,
        frequencyType: this.UpdateStatusRow.frequencyType,
        name: this.UpdateStatusRow.name,
        isDisableCommunication: this.UpdateStatusRow.isDisableCommunication,
      };
      this.announcementHttpService
        .post(RestApi.AnnouncementURL + "DisableAndEnable", inputObj)
        .subscribe(
          (response: any) => {
            if (this.UpdateStatusRow.isDisableCommunication == true) {
              this.messageService.add({
                severity: ToastMsg.severity.success,
                summary: this.translate.data.Popuop_Msg.EndaldleCommunication,
              });
            } else if (this.UpdateStatusRow.isDisableCommunication == false) {
              this.messageService.add({
                severity: ToastMsg.severity.success,
                summary: this.translate.data.Popuop_Msg.DisableCommunication,
              });
            }
            this.selectedRow = [];
            if (this.UpdateStatusRow.frequencyType == "DAILY") {
              this.loadRecurringDataDaily(this.recurringSelectedType);
            } else if (this.UpdateStatusRow.frequencyType == "WEEKLY") {
              this.loadRecurringDataWeekly(this.recurringSelectedType);
            } else if (this.UpdateStatusRow.frequencyType == "MONTHLY") {
              this.loadRecurringDataMonthly(this.recurringSelectedType);
            } else if (this.UpdateStatusRow.frequencyType == "YEARLY") {
              this.loadRecurringDataYearly(this.recurringSelectedType);
            }
          },
          (error) => {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: error.status + " " + error.statusText,
            });
            this.UpdateStatusRow.isDisableCommunication = !this.UpdateStatusRow
              .isDisableCommunication;
          }
        );
    } else {
      this.UpdateStatusRow.isDisableCommunication = this.UpdateStatusRow.isDisableCommunication;
      if (this.UpdateStatusRow.frequencyType == "DAILY") {
        this.loadRecurringDataDaily(this.recurringSelectedType);
      } else if (this.UpdateStatusRow.frequencyType == "WEEKLY") {
        this.loadRecurringDataWeekly(this.recurringSelectedType);
      } else if (this.UpdateStatusRow.frequencyType == "MONTHLY") {
        this.loadRecurringDataMonthly(this.recurringSelectedType);
      } else if (this.UpdateStatusRow.frequencyType == "YEARLY") {
        this.loadRecurringDataYearly(this.recurringSelectedType);
      }
    }
  }

  updateRowSelection(isChecked: boolean, rowData: any) {
    this.selectedRow = [];
    this.clickedStatus[this.checkBoxSelected] = false;
    if (isChecked) {
      this.checkBoxSelected = isChecked
        ? rowData.communicationDefID
        : undefined;
      this.clickedStatus[this.checkBoxSelected] = true;
    } else {
      this.clickedStatus[this.checkBoxSelected] = false;
    }

    if (isChecked) {
      this.selectedRow.push(rowData);
      this.selectedRowLength = this.selectedRow.length;
    } else {
      this.selectedRowLength = 0;
      this.selectedRow.splice(this.selectedRow.indexOf(rowData), 1);
    }
  }

  getNotificationType(data: any) {
    this.notificationData = data;
    if (this.notificationData.notificationType == "EMAIL") {
      return "email";
    } else if (this.notificationData.notificationType == "PUSH") {
      return "push";
    }
  }

  getAggrStatus(data: any) {
    this.statusData = data;
    if (this.statusData.aggStatus == "UNREAD") {
      return "unread";
    } else if (this.statusData.aggStatus == "READ") {
      return "read";
    } else if (this.statusData.aggStatus == "RESPONDED") {
      return "responded";
    }
  }
  reccurItemStatus(data: any) {
    this.reccurnItem = data;
    if (this.reccurnItem.status == "UNREAD") {
      return "unread";
    } else if (this.reccurnItem.status == "READ") {
      return "read";
    } else if (this.reccurnItem.status == "RESPONDED") {
      return "responded";
    }
  }
  getAllRegions(data: any) {
    if (data.regions.length > 0) {
      let regions = data.regions.map((x) => x.areas);
      let allregions = [].concat(...regions);
      return allregions;
    }
  }
  getAllSites(data: any) {
    if (data.regions.length > 0) {
      let regions = this.getAllRegions(data);
      let sites = regions.map((x) => x.sites);
      let allsites = [].concat(...sites);
      return allsites;
    }
  }
  getsiteName(data: any) {
    if (data.regions.length > 0) {
      let sites = this.getAllSites(data);
      return sites;
    }
    return "";
  }
  getregionName(data: any) {
    if (data.regions.length > 0) {
      let areas = this.getAllRegions(data);
      return areas;
    }
    return "";
  }
  onetimeRadioChange(type: string) {
    this.onetimeSelectedType = type;
    this.loadOneTimeData(type);
  }
  recurringRadioDailyChange(type: string) {
    this.recurringSelectedType = type;
    if (this.roleId == Roles.ClientAdmin) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=daily&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=daily&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=daily&notificationType=" +
        type.toUpperCase();
    }
    this.announcementHttpService.get(this.dailyUrl).subscribe(
      (response: any) => {
        if (response.frequencyTypeTaskListMap != null) {
          this.frequencyLsitDaily = false;
          this.dailyData = response.frequencyTypeTaskListMap.DAILY;
          this.freqType = this.dailyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.dailyData, this.freqType);
          this.dailyRecords = this.dailyData.length;
        } else {
          this.frequencyLsitDaily = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
  }
  recurringRadioWeeklyChange(type: string) {
    this.recurringSelectedType = type;
    if (this.roleId == Roles.ClientAdmin) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=weekly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=weekly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=weekly&notificationType=" +
        type.toUpperCase();
    }
    this.announcementHttpService.get(this.dailyUrl).subscribe(
      (response: any) => {
        if (response.frequencyTypeTaskListMap != null) {
          this.frequencyLsitWeekly = false;
          this.weeklyData = response.frequencyTypeTaskListMap.WEEKLY;
          this.freqType = this.weeklyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.weeklyData, this.freqType);
          this.weeklyRecords = this.weeklyData.length;
        } else {
          this.frequencyLsitWeekly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
  }
  recurringRadioMonthlyChange(type: string) {
    this.recurringSelectedType = type;
    if (this.roleId == Roles.ClientAdmin) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=monthly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=monthly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=monthly&notificationType=" +
        type.toUpperCase();
    }
    this.announcementHttpService.get(this.dailyUrl).subscribe(
      (response: any) => {
        if (response.frequencyTypeTaskListMap != null) {
          this.frequencyLsitMonthly = false;
          this.monthlyData = response.frequencyTypeTaskListMap.MONTHLY;
          this.freqType = this.monthlyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.monthlyData, this.freqType);
          this.monthlyRecords = this.monthlyData.length;
        } else {
          this.frequencyLsitMonthly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
  }
  recurringRadioYearlyChange(type: string) {
    this.recurringSelectedType = type;
    if (this.roleId == Roles.ClientAdmin) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&frequencyType=yearly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.AreaManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&frequencyType=yearly&notificationType=" +
        type.toUpperCase();
    } else if (this.roleId == Roles.SiteManager) {
      this.dailyUrl =
        RestApi.AnnouncementURL +
        "announcement-message-list/clientView?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&frequencyType=yearly&notificationType=" +
        type.toUpperCase();
    }
    this.announcementHttpService.get(this.dailyUrl).subscribe(
      (response: any) => {
        if (response.frequencyTypeTaskListMap != null) {
          this.frequencyLsitYearly = false;
          this.yearlyData = response.frequencyTypeTaskListMap.YEARLY;
          this.freqType = this.yearlyData[0].frequencyType.toLowerCase();
          this.BindTableData(this.yearlyData, this.freqType);
          this.yearlyRecords = this.yearlyData.length;
        } else {
          this.frequencyLsitYearly = true;
        }
      },
      (error) => {
        console.error("unable to fetch records");
        console.log(error);
      }
    );
  }
  cancel() {}
  showOpenDialogRegions(id) {
    this.selectedIndex = id;
    if (this.selectedIndex) {
      this.OpenStatusRegions[this.selectedIndex] = true;
    }
  }
  showOpenDialogSites(id) {
    this.selectedIndex = id;
    if (this.selectedIndex) {
      this.OpenStatusSite[this.selectedIndex] = true;
    }
  }
  showOpenDialogRecipient(data) {
    this.selectedIndex = data;
    if (this.selectedIndex) {
      this.OpenStatusRecipient[this.selectedIndex] = true;
    }
  }
  getOverallStatus(data: any) {
    if (data.frequencyType == "DAILY") {
      if (data.notificationSummary[0].totalNoOfNotificationsUnRead > 0) {
        return "unread";
      }
    }
  }

  refreshTableData(load: boolean) {
    if (load) {
      this.onetimeSelectedType = "ALL";
      this.recurringSelectedType = "ALL";
      this.loadOneTimeData(this.onetimeSelectedType);
      this.loadRecurringDataDaily(this.recurringSelectedType);
      this.loadRecurringDataWeekly(this.recurringSelectedType);
      this.loadRecurringDataMonthly(this.recurringSelectedType);
      this.loadRecurringDataYearly(this.recurringSelectedType);
      this.loadtasklist.emit(true);
      this.closepStepper.nativeElement.click();
    }
  }
  checkBoxSelection(id: any) {
    return this.selectedRow.find((x) => x.communicationDefID == id);
  }

  openTaskModel(type: string) {
    if (type == "EDIT" && this.selectedRow[0].isEditable == false) {
      this.showModalComm = false;
    } else {
      this.showModalComm = true;
      this.menuType = type;
      this.popupTaskData =
        type == "EDIT" ? this.selectedRow[0].communicationDefID : null;
    }
  }
  closePopup() {
    this.selectedRow = [];
    this.menuType = "";
    this.popupTaskData = "";
  }
  messageListDownload(defId: any, type: any) {
    let url =
      RestApi.AnnouncementURL +
      "messageListDetail/export?communicationDefinitionId=" +
      defId +
      "&frequencyType=" +
      type.toLowerCase();
    this.http
      .get<Blob>(url, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          if (data.status == 204) {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: this.translate.data.Announcements.noData,
            });
          } else {
            var fileName = type.toLowerCase() + "MessageList.xlsx";
            FileSaver.saveAs(data.body, fileName);
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Announcements.downloadedSuccessfully,
            });
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  onPageChange(event, freqType) {
    if (this.currentPage === event.currentPage) {
      return;
    }
    this.currentPage = event.currentPage;
    this.currentOffset = event.currentOff;
    if (freqType == "onetime") {
      this.BindTableData(this.onetimeData, freqType);
    } else if (freqType == "daily") {
      this.BindTableData(this.dailyData, freqType);
    } else if (freqType == "weekly") {
      this.BindTableData(this.weeklyData, freqType);
    } else if (freqType == "monthly") {
      this.BindTableData(this.monthlyData, freqType);
    } else if (freqType == "yearly") {
      this.BindTableData(this.yearlyData, freqType);
    }
  }
  BindTableData(data: any, freqType) {
    if (freqType == "onetime") {
      this.onetimeDataRecord = data.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    } else if (freqType == "daily") {
      this.dailyDataRecord = data.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    } else if (freqType == "weekly") {
      this.weeklyDataRecord = data.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    } else if (freqType == "monthly") {
      this.monthlyDataRecord = data.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    } else if (freqType == "yearly") {
      this.yearlyDataRecord = data.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    }
  }
  tabChange() {
    if (this.activeIndex == 0) {
      this.loadOneTimeData(this.onetimeSelectedType);
      this.currentOffset = 0;
      this.currentPage = 1;
    }
    if (this.activeIndex == 1) {
      this.loadRecurringDataDaily(this.recurringSelectedType);
      this.loadRecurringDataWeekly(this.recurringSelectedType);
      this.loadRecurringDataMonthly(this.recurringSelectedType);
      this.loadRecurringDataYearly(this.recurringSelectedType);
      this.currentOffset = 0;
      this.currentPage = 1;
    }
  }
}
