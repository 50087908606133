import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-comm-dashboard",
  templateUrl: "./comm-dashboard.component.html",
  styleUrls: ["./comm-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommDashboardComponent implements OnInit {
  refreshTaskList: boolean = false;
  activeIndex: number = 0;
  viewforClient = false;
  showModal = false;
  constructor() {}
  tabChange() {
    if (this.activeIndex == 0) {
      this.refreshTaskList = false;
    }
    if (this.activeIndex == 1) {
      this.refreshTaskList = true;
    }
  }

  showCommStepperModal() {
    this.showModal = true;
  }
  ngOnInit(): void {}
}
