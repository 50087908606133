import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PennService } from "../common/penn.service";
import { Constant } from "../common/constants/constant";
import { HttpService } from "../common/services/http.service";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { MessageService } from "primeng/api";
import { TranslateService } from "../common/services/translate.service";
import { DatePipe } from "@angular/common";
import { RestApi } from "../common/constants/RestAPI";
import { SessionVariable } from "../common/class/storageLabel";

enum SensorType {
  Temperature = "Temperature",
  Door = "Door Contact",
  TemperatureHumidity = "Temperature & Humidity",
  Water = "Water Detection",
  CO2 = "CO2",
  Pressure = "Pressure",
}
@Component({
  selector: "app-delete-sensor",
  templateUrl: "./delete-sensor.component.html",
  styleUrls: ["./delete-sensor.component.scss"],
})
export class DeleteSensorComponent implements OnInit {
  cabinetName: string;
  sensorId: string;
  batteryLevel: number;
  signalStrength: number;
  temperature: number;
  humidity: number;
  door: number;
  water: number;
  sensorType = SensorType;
  selectedSensorType: string;
  TemperatureType: string;
  userProfile;
  createdDate;
  siteID: number;
  premiseID: number;
  clientID: number;
  sensorTemplateName: string;
  pressure;
  CO2;
  deviceType;

  constructor(
    private route: ActivatedRoute,
    public pennService: PennService,
    private httpService: HttpService,
    public messageService: MessageService,
    private translate: TranslateService,
    private router: Router,
    private _date: DatePipe
  ) {
    const routingState = this.router.getCurrentNavigation();
    this.deviceType = this.route.snapshot.params["deviceType"];
    if (routingState && routingState.extras.state) {
      const sensorData = routingState.extras.state.sensorData;
      this.sensorId = sensorData.sensorKey;
      this.cabinetName = sensorData.cabinetName;
      this.batteryLevel = sensorData.batteryLevel;
      this.signalStrength = sensorData.signalStrength;
      this.temperature = sensorData.temperature;
      this.humidity = sensorData.humidity;
      this.pressure = sensorData.pressure;
      this.CO2 = sensorData.CO2;
      this.door = sensorData.door;
      this.water = sensorData.waterDetection;
      this.selectedSensorType = sensorData.sensorType;
      this.sensorTemplateName = sensorData.sensorTemplateName;
    } else {
      this.routeToListGatewayDevicePage();
    }
  }

  ngOnInit(): void {
    this.TemperatureType = this.pennService.getStoredData("temperatureType");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.siteID = parseInt(this.pennService.getStoredData("siteID"));
    this.premiseID = parseInt(this.pennService.getStoredData("premiseID"));
    this.clientID = parseInt(this.pennService.getStoredData("clientID"));
  }

  getColorClass(signalStrength: number): string {
    if (signalStrength === null) {
      return "";
    } else if (signalStrength > -65) {
      return "green_signal";
    } else if (signalStrength > -75 && signalStrength <= -65) {
      return "orange_signal";
    } else if (signalStrength <= -75) {
      return "red_signal";
    } else {
      return "";
    }
  }

  getBatteryStatusClass(batteryStrength: number): string {
    if (batteryStrength === null) {
      return "";
    } else if (batteryStrength >= 70 && batteryStrength <= 100) {
      return "battery_full";
    } else if (batteryStrength >= 30 && batteryStrength <= 69) {
      return "battery_medium";
    } else if (batteryStrength >= 0 && batteryStrength <= 29) {
      return "battery_low";
    } else {
      return "";
    }
  }

  convertTemperature(value: number): number {
    if (this.TemperatureType === Constant.TemperatureType.Fahrenheit) {
      return Math.round(((value * 9) / 5 + 32) * 10) / 10;
    } else if (this.TemperatureType === Constant.TemperatureType.Celsius) {
      return Math.round(value * 10) / 10;
    }
    return value;
  }

  deleteSensor(): void {
    const payload = {
      SensorKey: this.sensorId,
      ClientID: this.clientID,
      SiteID: this.siteID,
      PremiseID: this.premiseID,
      ModifiedByID: this.userProfile.userID,
      ModifiedBy: this.userProfile.userName,
    };

    this.httpService
      .deleteWithPayload(RestApi.DeleteCommissionSensorDevice, payload)
      .subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.sensorDeleteSuccess,
          });
          setTimeout(() => {
            this.router.navigate(["/commissioning/listsensorgateway"]);
          }, 1500);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  routeToListGatewayDevicePage() {
    if (this.deviceType === "generic") {
      this.router.navigate([
        "/commissioning/listsensorgateway",
        this.deviceType,
      ]);
    } else {
      this.router.navigate(["/commissioning/listsensorgateway"]);
    }
  }
}
