import { Component, OnInit } from "@angular/core";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { TranslateService } from "src/app/common/services/translate.service";
import { Constant } from "src/app/common/constants/constant";

@Component({
  selector: "app-taskdashboard",
  templateUrl: "./taskdashboard.component.html",
  styleUrls: ["./taskdashboard.component.scss"],
})
export class TaskdashboardComponent implements OnInit {
  userProfile: any;
  roleId: any;
  roleName: string;
  viewforClient = false;
  clientDashboard: any;
  siteDashboard: any;
  refreshTaskList: boolean = false;
  refreshManageTab: boolean = true;
  refreshDashBoard: boolean = true;
  activeIndex: number = 0;
  widgetClicked;
  DashboardMenu = {
    DashBoard: this.translate.data.Dashboard.dashboard,
    Manage: this.translate.data.TaskManagement.manage,
    TaskList: this.translate.data.TaskManagement.tasklist,
  };
  constructor(
    private pennservice: PennService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userProfile.roleID;
    this.roleName = this.userProfile.roleName;
    if (
      this.roleId == Roles.ClientAdmin ||
      this.roleId == Roles.SuperAdmin ||
      this.roleId == Roles.AdminReseller ||
      this.roleId == Roles.AreaManager
    ) {
      this.clientDashboard = true;
    } else if (this.roleId == Roles.SiteManager) {
      this.siteDashboard = true;
    } else {
      //log error
    }
    if (
      this.roleName == Constant.Roles.superAdmin ||
      this.roleName == Constant.Roles.adminReseller
    ) {
      this.refreshDashBoard = true;
      this.refreshManageTab = false;
    } else {
      this.refreshManageTab = true;
      this.refreshDashBoard = false;
    }
  }
  toggleDashboard(event) {
    this.viewforClient = event;
  }
  refreshTaskListData(isReload: boolean) {
    if (isReload) this.refreshTaskList = isReload;
    else this.refreshTaskList = false;
  }
  tabChange(event: any) {
    this.widgetClicked = event.originalEvent.target.textContent;
    if (this.widgetClicked === this.DashboardMenu.DashBoard) {
      this.refreshDashBoard = true;
      this.refreshTaskList = false;
      this.refreshManageTab = false;
    }
    if (this.widgetClicked === this.DashboardMenu.Manage) {
      this.refreshTaskList = false;
      this.refreshDashBoard = false;
      this.refreshManageTab = true;
    }
    if (this.widgetClicked === this.DashboardMenu.TaskList) {
      this.refreshTaskList = true;
      this.refreshDashBoard = false;
      this.refreshManageTab = false;
    }
  }
}
