import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  SimpleChanges,
} from "@angular/core";
import { PennService } from "../common/penn.service";
import { HttpService } from "../common/services/http.service";
import {
  NotificationData,
  PrimeDropdown,
  ApiListResponse,
} from "../common/models/configuration.model";
import { RestApi } from "../common/constants/RestAPI";
import {
  AddCorrectiveActions,
  AddReasons,
} from "src/app/common/models/configuration.model";
import { AddSite } from "../common/models/client.model";
import { Constant } from "../common/constants/constant";
import { DatePipe } from "@angular/common";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { SessionVariable } from "../common/class/storageLabel";
import { TranslateService } from "../common/services/translate.service";

@Component({
  selector: "app-notification-panel",
  templateUrl: "./notification-panel.component.html",
  styleUrls: ["./notification-panel.component.scss"],
})
export class NotificationPanelComponent implements OnInit {
  SiteIds: string = "";
  SiteName: string[] = [];
  notificationList: NotificationData[] = [];
  clientID: number;
  modalTitle: string;
  ActionList: PrimeDropdown<AddCorrectiveActions, string>[] = [];
  ReasonList: PrimeDropdown<AddReasons, string>[] = [];
  addNotification: NotificationData = <NotificationData>{};
  userProfile: any;
  createdDate: string;
  selectedReason: AddReasons;
  selectedAction: AddCorrectiveActions;
  @Input() openCloseStatus;

  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;

  constructor(
    public pennService: PennService,
    private messageService: MessageService,
    private httpService: HttpService,
    private router: Router,
    private _date: DatePipe,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes["openCloseStatus"].currentValue &&
      this.notificationList.length === 0
    ) {
      this.getSiteData();
    }
  }

  getAllNotifications() {
    if (this.SiteIds != "") {
      this.pennService.showLoader = false;
      this.spinner.show("SideSpinner");
      this.httpService
        .get(RestApi.GetNotificationsforClient + "/" + this.SiteIds + "/0/5")
        .subscribe(
          (res: ApiListResponse<NotificationData>) => {
            if (res.IsSuccess && res.Data && res.Data != null) {
              this.notificationList = res.Data.filter(
                (val: NotificationData, indx: number) => {
                  return val.IsDismissed == false;
                }
              ).sort((first: NotificationData, next: NotificationData) =>
                first.RaisedOn > next.RaisedOn ? -1 : 1
              );
              this.notificationList.map(
                (val: NotificationData, idx: number) => {
                  val.SiteName = this.SiteName[val.SiteID];
                }
              );
            } else this.notificationList = [];

            this.pennService.showLoader = true;
            this.spinner.hide("SideSpinner");
          },
          (error: any) => {
            this.pennService.showLoader = true;
            this.spinner.hide("SideSpinner");
          }
        );
    }
  }

  DismissNotification(NotificationID: number) {
    this.pennService.showLoader = false;
    this.spinner.show("SideSpinner");
    this.httpService
      .delete(RestApi.DismissNotification, NotificationID)
      .subscribe(
        (res: any) => {
          if (res.IsSuccess) {
            this.getAllNotifications();
          }
          this.pennService.showLoader = true;
          this.spinner.hide("SideSpinner");
        },
        (error: any) => {
          this.pennService.showLoader = true;
          this.spinner.hide("SideSpinner");
        }
      );
  }

  DismissAlarm(NotificationData: NotificationData) {
    this.addNotification = NotificationData;
    this.addNotification.DismissedBy = this.userProfile.userName;
    this.addNotification.DismmisedOn = this.createdDate;
    this.ReasonList = [];
    this.ActionList = [];
    this.httpService
      .get(RestApi.GetAllReasonsBySiteID + String(this.clientID))
      .subscribe((res: ApiListResponse<AddReasons>) => {
        if (res.IsSuccess && res.Data) {
          res.Data.map((val: AddReasons, index: number) => {
            this.ReasonList.push({ value: val, label: val.ReasonText });
          });
        }
      });
  }

  GetActionbyReasonID() {
    this.ActionList = [];
    this.httpService
      .get(
        RestApi.get_corractions_by_reason +
          "/" +
          String(this.selectedReason.ReasonID)
      )
      .subscribe((res: ApiListResponse<AddCorrectiveActions>) => {
        if (res.IsSuccess && res.Data) {
          res.Data.map((val: AddCorrectiveActions, index: number) => {
            this.ActionList.push({ value: val, label: val.ActionText });
          });
        }
      });
  }

  SaveNotification() {
    this.addNotification.IsDismissed = true;
    this.addNotification.ReasonID = this.selectedReason.ReasonID;
    this.addNotification.ReasonText = this.selectedReason.ReasonText;
    this.addNotification.ActionID = this.selectedAction.ActionID;
    this.addNotification.ActionText = this.selectedAction.ActionText;
    this.httpService
      .post(RestApi.SaveNotification, this.addNotification)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.addcloseBtn.nativeElement.click();
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.alarmdismissedsuccessfully,
          });
          this.getAllNotifications();
        }
      });
  }

  getSiteData() {
    this.clientID = this.pennService.getStoredData("clientID");
    this.httpService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        if (res.IsSuccess && res.Data) {
          let SiteIDs: number[] = [];

          res.Data.map((value: AddSite, index: number) => {
            SiteIDs.push(value.SiteID);
            this.SiteName[value.SiteID] = value.SiteName;
          });

          this.SiteIds = SiteIDs.join(",");
          this.getAllNotifications();
        }
      });
  }

  Validate(): boolean {
    if (this.selectedReason && this.selectedAction)
      return !(
        this.selectedReason.ReasonID > 0 &&
        this.selectedAction.ActionID > 0 &&
        this.addNotification.Remarks
      );
    else return true;
  }

  showAll() {
    // localStorage.setItem('SiteIds', this.SiteIds);
    this.router.navigate(["/notifications/0"]);
  }
}
