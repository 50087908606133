import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { TranslateService } from "../common/services/translate.service";
import { HttpClient } from "@angular/common/http";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";

@Component({
  selector: "app-delete-gatway",
  templateUrl: "./delete-gateway.component.html",
  styleUrls: ["./delete-gateway.component.scss"],
})
export class DeleteGatewayComponent {
  options;
  gatewayData;
  disableDeleteFlag;
  deviceType;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private activeroute: ActivatedRoute
  ) {
    const routingInfo = this.router.getCurrentNavigation();
    this.deviceType = this.activeroute.snapshot.params["deviceType"];
    if (routingInfo && routingInfo.extras.state) {
      this.options = {
        body: this.router.getCurrentNavigation().extras.state.bodyParams,
      };
      this.gatewayData = this.router.getCurrentNavigation().extras.state.gatewayData;
      this.disableDeleteFlag = this.router.getCurrentNavigation().extras.state.deleteFlag;
    } else {
      this.routeToListGatewayDevicePage();
    }
  }

  getColorClass(signalStrength: number): string {
    if (signalStrength === null) {
      return "gray_signal";
    } else if (signalStrength >= 25) {
      return "green_signal";
    } else if (signalStrength < 25 && signalStrength > 0) {
      return "orange_signal";
    } else {
      return "gray_signal";
    }
  }

  deleteGateway() {
    this.httpClient
      .delete(RestApi.DeleteGatewayDevice, this.options)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: res.ReturnMessage,
          });
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
        setTimeout(() => {
          this.router.navigate(["/commissioning/listsensorgateway"]);
        }, 2500);
      });
  }

  routeToListGatewayDevicePage() {
    if (this.deviceType === "generic") {
      this.router.navigate([
        "/commissioning/listsensorgateway",
        this.deviceType,
      ]);
    } else {
      this.router.navigate(["/commissioning/listsensorgateway"]);
    }
  }
}
