<div class="box mtop-32">
  <div>
    <h6 class="commissioning-header col-sm-12 text-center">
      {{ "sensorDetails" | translate: "Commissioning" }}
    </h6>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div>
        <div class="col-md-12">
          <div class="value-header">
            {{ "locationName" | translate: "Notification" }}
          </div>
          <div>
            <div class="cabinet-menu">
              <p-dropdown
                class="dropdown"
                name="reasons"
                optionLabel="CabinetName"
                [placeholder]="cabinetName"
                [disabled]="true"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="value-header">
          {{ "sensorId" | translate: "Commissioning" }}
        </div>
        <div class="value">
          {{
            this.sensorId
              ? this.sensorId
              : ("notAvailable" | translate: "Commissioning")
          }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="value-header">
          {{ "template" | translate: "Commissioning" }}
        </div>
        <div class="cabinet-menu">
          <p-dropdown
            class="dropdown"
            name="template"
            optionLabel="Name"
            [placeholder]="sensorTemplateName"
            [disabled]="true"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6">
          <div class="value-header">
            {{ "batteryLevel" | translate: "Refrigeration" }}
          </div>
          <div class="value text-css">
            <div
              class="battery"
              [ngClass]="{
                'green-bg':
                  getBatteryStatusClass(this.batteryLevel) === 'battery_full',
                'orange-bg':
                  getBatteryStatusClass(this.batteryLevel) === 'battery_medium',
                'red-bg':
                  getBatteryStatusClass(this.batteryLevel) === 'battery_low',
                'not-available-text':
                  getBatteryStatusClass(this.batteryLevel) === ''
              }"
            >
              <span
                class="flex"
                [ngClass]="{
                  'green-text':
                    getBatteryStatusClass(this.batteryLevel) === 'battery_full',
                  'orange-text':
                    getBatteryStatusClass(this.batteryLevel) ===
                    'battery_medium',
                  'red-text':
                    getBatteryStatusClass(this.batteryLevel) === 'battery_low',
                  'not-available-text':
                    getBatteryStatusClass(this.batteryLevel) === ''
                }"
              >
                <svg
                  *ngIf="this.batteryLevel"
                  style="height: 25px; width: 25px"
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getBatteryStatusClass(this.batteryLevel)
                    "
                  ></use>
                </svg>
                <span
                  *ngIf="this.batteryLevel"
                  class="spacing_batterySingnalInfo"
                >
                  {{ this.batteryLevel + " %" }}
                </span>
                <span *ngIf="!this.batteryLevel">
                  {{ "notAvailable" | translate: "Commissioning" }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="value-header">
            {{ "signalStrength" | translate: "Commissioning" }}
          </div>
          <div class="value text-css">
            <div
              class="signal"
              [ngClass]="{
                'green-bg':
                  getColorClass(this.signalStrength) === 'green_signal',
                'orange-bg':
                  getColorClass(this.signalStrength) === 'orange_signal',
                'red-bg': getColorClass(this.signalStrength) === 'red_signal',
                'not-available-text': getColorClass(this.signalStrength) === ''
              }"
            >
              <span
                class="sensorcard-grid_info flex"
                [ngClass]="{
                  'green-text':
                    getColorClass(this.signalStrength) === 'green_signal',
                  'orange-text':
                    getColorClass(this.signalStrength) === 'orange_signal',
                  'red-text':
                    getColorClass(this.signalStrength) === 'red_signal',
                  'not-available-text':
                    getColorClass(this.signalStrength) === ''
                }"
              >
                <svg
                  *ngIf="this.signalStrength"
                  style="height: 25px; width: 25px"
                  [ngClass]="getColorClass(this.signalStrength)"
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getColorClass(this.signalStrength)
                    "
                  ></use>
                </svg>
                <span
                  *ngIf="this.signalStrength"
                  class="spacing_batterySingnalInfo"
                >
                  {{ this.signalStrength + " dBm" }}
                </span>
                <span *ngIf="!this.signalStrength">
                  {{ "notAvailable" | translate: "Commissioning" }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          selectedSensorType === sensorType.Temperature ||
          selectedSensorType === sensorType.TemperatureHumidity ||
          selectedSensorType === sensorType.CO2
        "
      >
        <div class="col-md-6">
          <div class="value-header">
            {{ "airTemperature" | translate: "Refrigeration" }}
          </div>
          <div class="value text-css">
            <span
              class="green-text spacing-temperatureInfo"
              *ngIf="this.temperature || this.temperature === 0"
            >
              {{
                convertTemperature(this.temperature) +
                  " °" +
                  (this.TemperatureType === "F" ? "F" : "C")
              }}
            </span>
            <span
              class="not-available-text"
              *ngIf="!this.temperature && this.temperature !== 0"
            >
              {{ "notAvailable" | translate: "Commissioning" }}
            </span>
          </div>
        </div>
        <div
          *ngIf="
            selectedSensorType === sensorType.TemperatureHumidity ||
            selectedSensorType === sensorType.CO2
          "
          class="col-md-6"
        >
          <div>
            <div class="value-header">
              {{ "humidity" | translate: "Refrigeration" }}
            </div>
            <div>
              <span
                *ngIf="this.humidity || this.humidity === 0"
                class="value text-css spacing-temperatureInfo"
              >
                {{ this.humidity + " % RH" }}
              </span>
              <span
                *ngIf="!this.humidity && this.humidity !== 0"
                class="not-available"
              >
                {{ "notAvailable" | translate: "Commissioning" }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="selectedSensorType === sensorType.CO2">
        <div class="col-md-6">
          <div class="value-header">CO2</div>
          <div class="value text-css green-text spacing-temperatureInfo">
            <span class="green-text" *ngIf="this.CO2 || this.CO2 === 0">
              {{ this.CO2 }} ppm
            </span>
            <span
              class="not-available-text"
              *ngIf="!this.CO2 && this.CO2 !== 0"
            >
              {{ "notAvailable" | translate: "Commissioning" }}
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <div class="value-header">
              {{ "Pressure" | translate: "Refrigeration" }}
            </div>
            <div>
              <span
                *ngIf="this.pressure || this.pressure === 0"
                class="value text-css spacing-temperatureInfo green-text"
              >
                {{ this.pressure + " hPa" }}
              </span>
              <span
                *ngIf="!this.pressure && this.pressure !== 0"
                class="not-available"
              >
                {{ "notAvailable" | translate: "Commissioning" }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="selectedSensorType === sensorType.Door">
        <div class="col-md-12">
          <div>
            <div class="value-header">
              {{ "door" | translate: "Commissioning" }}
            </div>
            <div class="value text-css">
              <span
                [ngClass]="{
                  'red-text': this.door === 1,
                  'green-text': this.door === 0
                }"
              >
                {{
                  this.door === 1
                    ? ("open" | translate: "Refrigeration")
                    : ("closed" | translate: "Commissioning")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedSensorType === sensorType.Water">
        <div class="col-md-12">
          <div>
            <div class="value-header">
              {{ "water" | translate: "Commissioning" }}
            </div>
            <div class="value text-css">
              <span
                [ngClass]="{
                  'red-text': this.water === 1,
                  'green-text': this.water === 0
                }"
              >
                {{
                  this.water === 1
                    ? ("detected" | translate: "Commissioning")
                    : ("notdetected" | translate: "Commissioning")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12 text-center mtop-32">
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#delete"
      >
        {{ "delete" | translate: "Common" }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="routeToListGatewayDevicePage()"
      >
        {{ "cancel" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>
<div class="modal fade" id="delete" data-backdrop="true" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteSensorTitle" | translate: "Commissioning" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteSensorMessage" | translate: "Commissioning" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
          #addcloseBtn
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="deleteSensor()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
