import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from "ngx-webstorage-service";
import { AuthenticationService } from "../services/authentication.service";
import { AppCookieService } from "../services/cookie.service";
import { environment } from "src/environments/environment";
import { retry } from "rxjs/operators";

@Component({
  selector: "app-auth-process",
  templateUrl: "./auth-process.component.html",
  styleUrls: ["./auth-process.component.scss"],
})
export class AuthProcessComponent implements OnInit {
  commonAuthUrl: string = "";
  tokenKey: string = "sso.accessToken";
  userId;
  noAccessFlag = false;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private cookieService: AppCookieService,
    @Inject(LOCAL_STORAGE) private localstorage: StorageService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const uuid = params["uuid"];
      const userId = params["userid"];
      const tenant = params["tenant"];
      const token = params["token"];
      const refreshToken = params["refreshToken"];
      // delete the query params without refresh or state
      window.history.replaceState(null, null, window.location.pathname);
      const req = {
        url: environment.commonAuthUrl + "/v1/api/token/" + uuid,
      };

      if (token) {
        // save token to cookie
        this.saveUserData(token);
        this.cookieService.setToCookie("currentUser", token, 1);
        // save tenant in cookie
        this.cookieService.setToCookie("tenant", tenant, 1);
        const tokens: any = JSON.parse(atob(token.split(".")[1]));
        if (tokens.apps.includes("penn")) {
          this.authenticationService.getRefreshToken(refreshToken);
          this.authenticationService.getUserDetails(userId);
        } else {
          this.authenticationService.logout(userId);
        }
      } else if (uuid) {
        const getTokenApi = this.http.get(req.url);
        getTokenApi.pipe(retry(1)).subscribe(
          (res: any) => {
            if (res.idToken) {
              const ssotoken = res.idToken;
              const refreshtoken = res.refreshToken;
              this.saveUserData(ssotoken);
              this.saverefreshToken(refreshtoken);
              // save token to cookie
              this.cookieService.setToCookie("currentUser", ssotoken, 1);
              // save tenant in cookie
              this.cookieService.setToCookie("tenant", tenant, 1);
              // decoding jwt token payload to check if token has access to smaas
              const tokens: any = JSON.parse(atob(ssotoken.split(".")[1]));
              this.userId = tokens.user_id;
              if (tokens.apps.includes("penn")) {
                this.authenticationService.getRefreshToken(res.refreshToken);
                this.authenticationService.getUserDetails(userId);
              } else {
                this.authenticationService.logout(userId);
              }
            }
          },
          (err) => {
            // if error, navigate to login page
            this.authenticationService.logout(userId);
          }
        );
      }
    });
  }

  saveUserData(token: any) {
    this.localstorage.set(this.tokenKey, token);
  }

  saverefreshToken(token) {
    this.localstorage.set("refresh_token", token);
  }
}
