import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as Highcharts from "highcharts";
//import HC_heatmap from "highcharts/modules/heatmap";
import { HttpService } from "src/app/common/services/http.service";
import moment, { months } from "moment";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
//HC_heatmap(Highcharts);
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { RestApi } from "src/app/common/constants/RestAPI";
import { TranslateService } from "src/app/common/services/translate.service";

interface Regions {
  name: string;
  code: string;
}

@Component({
  selector: "app-commstatus",
  templateUrl: "./commstatus.component.html",
  styleUrls: ["./commstatus.component.scss"],
})
export class CommstatusComponent implements OnInit {
  @Input() reload: boolean;
  type: Regions[];
  selectedType: string;
  frequency: Regions[];
  selectedFrequency: string;
  commStatusData: any;
  total: number;
  userProfile: any;
  roleId: any;
  siteId: number;
  clientID: any;
  siteID: any;
  apiData: string;
  regionId: any;
  noDataEamil: boolean = false;
  noDataPush: boolean = false;
  eamilRead: any;
  eamilUnRead: any;
  notifyRead: any;
  notifyUnRead: any;
  notifyRespond: any;
  emailRespond: any;
  chartData: any;
  emailData: boolean = true;
  pushData: boolean = true;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    public messageApiService: HttpService,
    public pennservice: PennService,
    private translate: TranslateService
  ) {
    this.type = [
      { name: "Email", code: "E" },
      { name: this.translate.data.Announcements.pushNotification, code: "P" },
    ];
    this.frequency = [
      { name: this.translate.data.Announcements.thisWeek, code: "w0" },
      { name: this.translate.data.Announcements.lastWeek, code: "-w1" },
      { name: this.translate.data.Announcements.thisMonth, code: "m0" },
      { name: this.translate.data.Announcements.lastMonth, code: "-m1" },
    ];
  }

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userProfile.roleID;
    this.selectedType = "E";
    this.selectedFrequency = "w0";
    this.clientID = this.pennservice.getStoredData("clientID");
    this.siteID = this.userProfile.siteID;
    this.regionId = this.userProfile.areaID;
    this.getData();
  }
  ngOnChanges() {
    if (this.reload) {
      this.selectedFrequency = "w0";
      this.selectedType = "E";
      this.getData();
    }
  }
  getData() {
    let startDate = "";
    let endDate = "";
    if (this.selectedFrequency == "w0") {
      startDate = moment(new Date())
        .subtract(0, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(0, "weeks")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    }
    if (this.selectedFrequency == "-w1") {
      startDate = moment(new Date())
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    }
    if (this.selectedFrequency == "m0") {
      startDate = moment(new Date())
        .subtract(0, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(0, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    if (this.selectedFrequency == "-m1") {
      startDate = moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }

    this.messageBreakDownData(this.selectedType, startDate, endDate);
  }
  messageBreakDownData(
    notificationType: string,
    startDate: string,
    endDate: string
  ) {
    if (this.roleId == Roles.ClientAdmin) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&notificationType=" +
        notificationType +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    } else if (this.roleId == Roles.AreaManager) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&regionId=" +
        this.regionId +
        "&notificationType=" +
        notificationType +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    } else if (this.roleId == Roles.SiteManager) {
      this.apiData =
        RestApi.AnnouncementURL +
        "api/announcement/communicationStatus?clientId=" +
        this.clientID +
        "&siteId=" +
        this.siteID +
        "&notificationType=" +
        notificationType +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
    }

    this.messageApiService.get(this.apiData).subscribe(
      (data: any) => {
        this.noDataEamil = false;
        this.noDataPush = false;
        this.emailData = true;
        this.pushData = true;
        this.commStatusData = data.notificationTypeStatus[0];
        if (
          this.selectedType == "E" &&
          this.commStatusData.totalNoOfEmailsSent == 0
        ) {
          this.noDataEamil = true;
        } else if (
          this.selectedType == "P" &&
          this.commStatusData.totalNoOfNotificationsSent == 0
        ) {
          this.noDataPush = true;
        } else {
          (this.total =
            this.selectedType == "E"
              ? this.commStatusData.totalNoOfEmailsSent
              : this.commStatusData.totalNoOfNotificationsSent),
            (this.eamilRead = this.commStatusData.totalNoOfEmailsRead);
          this.eamilUnRead = this.commStatusData.totalNoOfEmailsUnRead;
          this.emailRespond = null;
          this.notifyRead = this.commStatusData.totalNoOfNotificationsRead;
          this.notifyUnRead = this.commStatusData.totalNoOfNotificationsUnRead;
          this.notifyRespond = this.commStatusData.totalNoOfNotificationsResponded;
          if (this.eamilRead == 0) {
            this.eamilRead = null;
          }
          if (this.eamilUnRead == 0) {
            this.eamilUnRead = null;
          }
          if (this.notifyRead == 0) {
            this.notifyRead = null;
          }
          if (this.notifyUnRead == 0) {
            this.notifyUnRead = null;
          }
          if (this.notifyRespond == 0) {
            this.notifyRespond = null;
          }
          if (this.selectedType == "E") {
            this.chartData = [
              {
                name: this.translate.data.Announcements.read,
                color: "#868585",
                y: this.selectedType == "E" ? this.eamilRead : this.notifyRead,
              },
              {
                name: this.translate.data.Announcements.unread,
                color: "#CA2339",
                y:
                  this.selectedType == "E"
                    ? this.eamilUnRead
                    : this.notifyUnRead,
              },
              {
                name: "&nbsp",
                color: "#fff",
                y:
                  this.selectedType == "E"
                    ? this.emailRespond
                    : this.notifyRespond,
              },
            ];
          } else {
            this.chartData = [
              {
                name: this.translate.data.Announcements.read,
                color: "#868585",
                y: this.selectedType == "E" ? this.eamilRead : this.notifyRead,
              },
              {
                name: this.translate.data.Announcements.unread,
                color: "#CA2339",
                y:
                  this.selectedType == "E"
                    ? this.eamilUnRead
                    : this.notifyUnRead,
              },
              {
                name: this.translate.data.Announcements.responded,
                color: "#F47721",
                y:
                  this.selectedType == "E"
                    ? this.emailRespond
                    : this.notifyRespond,
              },
            ];
          }
          if (this.selectedType == "E") {
            this.emailData = true;
            this.pushData = false;
            setTimeout(() => {
              this.createPieChart();
            }, 300);
          } else {
            this.pushData = true;
            this.emailData = false;
            setTimeout(() => {
              this.createPieChartP();
            }, 300);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createPieChart(): void {
    let self = this;
    const chart = Highcharts.chart("pie-email", {
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        events: {
          render: function () {
            var chart = this,
              centerX = chart.plotLeft + chart.series[0].center[0],
              centerY = chart.plotTop + chart.series[0].center[1],
              radius = chart.series[0].center[2] / 2;

            let series = chart.series[0];
            let customLabel;
            if (!chart.customCircle) {
              chart.customCircle = chart.renderer
                .circle(centerX, centerY, radius)
                .attr({
                  fill: "#8cc63e", // Semi-transparent red
                  stroke: "black",
                  "stroke-width": 2,
                })
                .add();
              chart.customText = customLabel = chart.renderer
                .label(
                  `<strong>${self.total}</strong><br/>
                  ${self.translate.data.Announcements.emailSent}`,
                  centerX,
                  centerY
                )
                .css({
                  color: "#FFFF",
                  fontSize: "17px",
                  textAnchor: "middle",
                  paddingBottom: "20px",
                })
                .add();

              const x = series.center[0] + chart.plotLeft,
                y =
                  series.center[1] +
                  chart.plotTop -
                  customLabel.attr("height") / 2;

              customLabel.attr({
                x,
                y,
              });
            } else {
              chart.customCircle.attr({
                cx: centerX,
                cy: centerY,
                r: radius,
              });

              chart.customText.attr({
                x: centerX,
                y: centerY,
              });
            }
          },
        },
      },
      title: {
        text: null,
      },
      accessibility: {
        enabled: false,
      },
      legend: {
        enabled: true,
        align: "center",
        x: 60,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          cursor: "pointer",
          innerSize: "100%",
          dataLabels: {
            enabled: true,
            format: "{point.y}",
            distance: -30,
            color: "#fff",
          },
        },
      },
      series: [
        {
          enableMouseTracking: false,
          type: "pie",
          innerSize: "50%",
          data: this.chartData,
        },
      ],
    } as any);
  }

  createPieChartP(): void {
    let self = this;
    const chart = Highcharts.chart("pie-push", {
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        events: {
          render: function () {
            var chart = this,
              centerX = chart.plotLeft + chart.series[0].center[0],
              centerY = chart.plotTop + chart.series[0].center[1],
              radius = chart.series[0].center[2] / 2;

            let series = chart.series[0];
            let customLabel;
            if (!chart.customCircle) {
              chart.customCircle = chart.renderer
                .circle(centerX, centerY, radius)
                .attr({
                  fill: "#8cc63e", // Semi-transparent red
                  stroke: "black",
                  "stroke-width": 2,
                })
                .add();
              chart.customText = customLabel = chart.renderer
                .label(
                  `<strong>${self.total}</strong><br/>
                  ${self.translate.data.Announcements.pushNotification}`,
                  centerX,
                  centerY
                )
                .css({
                  color: "#FFFF",
                  fontSize: "14px",
                  textAnchor: "middle",
                })
                .add();

              const x = series.center[0] + chart.plotLeft,
                y =
                  series.center[1] +
                  chart.plotTop -
                  customLabel.attr("height") / 2;

              customLabel.attr({
                x,
                y,
              });
            } else {
              chart.customCircle.attr({
                cx: centerX,
                cy: centerY,
                r: radius,
              });

              chart.customText.attr({
                x: centerX,
                y: centerY,
              });
            }
          },
        },
      },
      title: {
        text: null,
      },
      accessibility: {
        enabled: false,
      },
      legend: {
        enabled: true,
        align: "center",
        x: 100,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          cursor: "pointer",
          innerSize: "100%",
          dataLabels: {
            enabled: true,
            format: "{point.y}",
            distance: -30,
            color: "#fff",
          },
        },
      },
      series: [
        {
          enableMouseTracking: false,
          type: "pie",
          innerSize: "50%",
          data: this.chartData,
        },
      ],
    } as any);
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Message Breakdown": { modulename: "announcementModule" },
    });
  }
}
