<app-side-menu
  *ngIf="userProfile.roleName | hideMenuForUnauthorised"
></app-side-menu>
<app-notification-panel
  [openCloseStatus]="this.pennService.displayNotification"
  *ngIf="routeUrl == 'site'"
></app-notification-panel>
<header>
  <div class="header header_position removeRightPadding">
    <div class="header_leftMenu">
      <ul class="pt-2 pb-2 header_leftMenu--section">
        <li>
          <i
            class="fa fa-bars header_leftMenu--section_toggleBtn"
            (click)="pennService.toggleSideMenu()"
          ></i>
        </li>
        <li class="header_leftMenu--section_sensormaticLogoDiv">
          <a href="https://www.sensormatic.com/" target="_blank">
            <img
              src="../../assets/icons-svg/logo.svg"
              class="img-respsonsive sensormatic-logo"
            />
          </a>
          <div class="separator"></div>
          <h3 class="header_leftMenu--section_sensormaticLogoDiv_header">
            Compliance Manager
          </h3>
          <img
            class="client_logo"
            *ngIf="
              logo &&
              (this.userProfile.roleName == 'Client Admin' ||
                this.userProfile.roleName == 'Regional Manager' ||
                this.userProfile.roleName == 'Site Manager')
            "
            [src]="logo"
          />
        </li>
      </ul>
    </div>
    <div class="header_rightMenu">
      <div *ngIf="this.endEmulationFlag" class="emulated-banner">
        Emulated User
      </div>
      <div class="profile-pic">
        {{ userProfile?.userName?.substring(0, 1) }}
      </div>
      <div class="btn-group user-profile-dd dropdown.dropdown_css_override">
        <button
          id="button-autoclose1"
          type="button"
          class="ui-g-12 ui-md-2 btn export-setting btn2 dropbtn dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          class="header_rightMenu_profileName"
        >
          <div>
            <p class="header_rightMenu_profileName_namelabel">
              {{ userProfile.userName }}
            </p>
            <p class="header_rightMenu_profileName_roleLabel">
              {{ userProfile.roleName }}
            </p>
          </div>
          <div class="dropdown-icon">
            <i class="fa fa-angle-down"></i>
          </div>
        </button>

        <div
          *ngIf="slowload"
          class="dropdown-menu dropdown-menu_override"
          aria-labelledby="button-autoclose1"
        >
          <a
            class="dropdown-item"
            *ngIf="userProfile.roleName | hideMenuForUnauthorised"
            (click)="editUserProfile()"
          >
            {{ "userProfile" | translate: "Common" }}
          </a>
          <a
            class="dropdown-item"
            *ngIf="
              (userProfile.roleName | hideMenuForUnauthorised) &&
              changeUserFlag &&
              currentPage === 'clientPage'
            "
            data-toggle="modal"
            data-target="#emulateUser"
            (click)="changeUser()"
          >
            {{ "emulateUser" | translate: "Client" }}
          </a>
          <a
            class="dropdown-item"
            *ngIf="endEmulationFlag"
            (click)="gotoPreviousLoggedUser()"
          >
            {{ "endEmulation" | translate: "Client" }}
          </a>
          <a class="dropdown-item" (click)="logout()">
            {{ "logout" | translate: "Common" }}
          </a>
        </div>
        <div
          class="slider-menu-icon"
          [ngClass]="{
            pos_left: !pennService.displayNotification,
            pos_right: pennService.displayNotification
          }"
          *ngIf="routeUrl == 'site'"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-angle-right pos_left': !pennService.displayNotification,
              'fa-angle-left pos_right': pennService.displayNotification
            }"
            (click)="pennService.toggleNotification()"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <edit-user-profile
    *ngIf="showUser == true"
    (headerComponentRef)="onClose($event)"
    [selfEdit]="true"
  ></edit-user-profile>

  <div class="modal fade" data-keyboard="false" id="emulateUser">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ "emulateUser" | translate: "Client" }}</h5>
          <button
            type="button"
            class="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            #closeBtn
            (click)="valuesReset()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="{{ 'role' | translate: 'Common' }}">
              {{ "role" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="role"
              [options]="roleList"
              [(ngModel)]="selectedRole"
              placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                'role' | translate: 'Common'
              }}"
              optionLabel="name"
              (onChange)="onSelectRole()"
            ></p-dropdown>
          </div>

          <div>
            <div *ngIf="!adminFlag" class="form-group">
              <label for="{{ 'roles' | translate: 'Common' }}">
                {{ "clients" | translate: "Client" }}
                <span class="mandatory">*</span>
              </label>
              <p-dropdown
                class="dropdown"
                name="client"
                [options]="clientList"
                [(ngModel)]="selectedClient"
                optionLabel="ClientName"
                placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                  'client' | translate: 'Client'
                }}"
                filter="true"
                (onChange)="onSelectClient()"
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <label for="{{ 'roles' | translate: 'Common' }}">
              users
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              [options]="filtereduserList"
              filter="true"
              [(ngModel)]="selectedUser"
              placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                'user' | translate: 'User'
              }}"
              optionLabel="UserName"
            ></p-dropdown>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            [disabled]="navigateMenuValidation()"
            (click)="navigate()"
          >
            {{ "navigateToselectedUser" | translate: "Client" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
