<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/site/{{ this.clientID }}/{{ this.siteName }}">
              {{ this.premisesName }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Sensors" | translate: "Refrigeration" }}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 flex flex-space_between flex_wrap m-24 aic">
      <div>
        <div>
          <div class="tblIconDiv">
            <span class="page-title">
              {{ "Sensors" | translate: "Refrigeration" }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex aic">
        <span title="{{ 'refresh' | translate: 'Common' }}">
          <svg (click)="getCabinets()" class="svg-icon-refresh cp">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
            ></use>
          </svg>
        </span>
        <div
          *ngIf="
            premisesName && premisesName.length > 0 && this.tab === 'cabinets'
          "
        >
          <button
            type="button"
            class="btn btn-primary btncust"
            data-toggle="modal"
            data-target="#clientStatusModal"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
                fill="white"
              />
            </svg>
            {{ "exportTemperature" | translate: "Refrigeration" }}
          </button>
          <button
            *ngIf="showRequestCalibrationButton"
            class="btn btn-primary export_btn_css btncust"
            (click)="requestCalibrationCert()"
          >
            {{ "requestCalibrationcerts" | translate: "Refrigeration" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="store_name_section">
        <div
          class="flex textoverflow-initial aic"
          style="min-width: 159px; max-width: 300px"
        >
          <h5 class="store_name_section_label">
            {{ this.premisesName }}
          </h5>
          <p class="store_name_section_location">
            {{ this.siteName }}
          </p>
        </div>
        <div>
          <div
            class="font-size12 font-weight600 text-uppercase last_communicationTime"
          >
            <span>{{ "lastSeen" | translate: "Site" }} -</span>
            <span>
              {{ this.gatewayTime }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 tabs-custom refrigirationClass">
      <p-tabView [activeIndex]="activeIndex" (onChange)="handleChange($event)">
        <p-tabPanel header="{{ 'locations' | translate: 'Refrigeration' }}">
          <div class="flex" style="padding-bottom: 10px">
            <div
              class="cp flex aic filterMenu"
              *ngFor="let item of this.sensorFilterMenu"
              (click)="filterBasedOnSensorType(item)"
              [ngClass]="{ active: item === this.filterText }"
            >
              {{ item }}
            </div>
          </div>
          <ul class="data-tile" *ngIf="refrigLocationList">
            <li
              #card_class
              *ngFor="
                let item of refrigLocationList | searchFilter: this.filterText;
                let i = index
              "
              [routerLink]="['/refrigerationDetails', item.unit_id]"
              [queryParams]="{
                networkdetails: item.networkstatus,
                batterydetails: item.batterystatus
              }"
              (click)="setDeviceList(item)"
              queryParamsHandling="merge"
            >
              <div class="topdiv">
                <div
                  class="sensorDiv"
                  [ngClass]="{
                    lengthtwo: item.sensorLength == 2,
                    lengthfour: item.sensorLength == 4,
                    lengthfive: item.sensorLength == 5
                  }"
                >
                  <div class="btmdiv" *ngFor="let data of item.sensors">
                    <h6
                      [ngClass]="{
                        red: data | red_color: item,
                        green: data | green_color: item
                      }"
                    >
                      <div *ngIf="data.tempname !== 'NA'">
                        <div class="flex aic">
                          <div
                            *ngIf="data.key === '3000'"
                            [ngClass]="{
                              water_icon_red: data | red_color: item,
                              water_icon_green: data | green_color: item
                            }"
                          ></div>
                          {{ data.tempname }}
                          <span style="padding-left: 5px">
                            {{ data.unit }}
                          </span>
                        </div>
                        <p class="btmdiv_description">
                          {{ data.SensorDescription }}
                        </p>
                      </div>
                    </h6>
                    <span *ngIf="data.tempname === 'NA'">
                      <p class="no-data">
                        {{ "nodata" | translate: "Common" }}
                      </p>
                      <p class="btmdiv_description">
                        {{ data.SensorDescription }}
                      </p>
                    </span>
                  </div>

                  <div *ngIf="item?.child_sensor?.length > 0">
                    <div
                      class="btmdiv"
                      *ngFor="let childdata of item?.child_sensor"
                    >
                      <h6
                        [ngClass]="{
                          red: childdata | red_color: item,
                          green: childdata | green_color: item
                        }"
                      >
                        <span *ngIf="childdata.tempname !== 'NA'">
                          {{ childdata.tempname }}
                          <span
                            *ngIf="
                              childdata.unit === '°C' ||
                              childdata.unit === '°F' ||
                              childdata.unit === '%'
                            "
                          >
                            {{ childdata.unit_of_measurement.um_abbreviated }}
                          </span>
                          <p class="btmdiv_description">
                            {{ childdata.SensorDescription }}
                          </p>
                        </span>
                      </h6>
                      <span *ngIf="childdata.tempname === 'NA'">
                        <p class="no-data">
                          {{ "nodata" | translate: "Common" }}
                        </p>
                        <p class="btmdiv_description">
                          {{ childdata.SensorDescription }}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <i class="bellSection" *ngIf="item.uncleared_alarms > 0">
                  <i class="fa fa-bell"></i>
                  <span class="button__badge">{{ item.uncleared_alarms }}</span>
                </i>
                <h3 *ngIf="item.uncleared_alarms > 0">
                  {{ item.description | delimit: "18" }}
                </h3>
                <h3 *ngIf="item.uncleared_alarms == 0">
                  {{ item.description }}
                </h3>
                <div class="pt-2 font-11">
                  {{ "lastSeen" | translate: "Site" }} :
                  {{ item.lastcommunicatedtimestamp }}
                </div>
              </div>
              <div
                *ngIf="
                  item?.batterystatus !== 'NA' || item?.networkstatus !== 'NA'
                "
                class="battery-signal-div"
              >
                <div class="show-context div-width">
                  <div
                    title="{{ item.networkstatus }}dbm  {{
                      item.networkupdatetime
                    }}"
                    *ngIf="item?.networkstatus !== 'NA'"
                    [ngClass]="{
                      good_signal_image: item.networkstatus > -65,
                      medium_signal_image:
                        item.networkstatus > -75 && item.networkstatus <= -65,
                      weak_signal_image: item.networkstatus <= -75
                    }"
                  ></div>
                </div>
                <div class="show-context div-width">
                  <div
                    title="{{ item.batterystatus }}%  {{
                      item.batteryupdatetime
                    }}"
                    *ngIf="item?.batterystatus !== 'NA'"
                    [ngClass]="{
                      good_strength_battery_image:
                        item.batterystatus >= 70 && item.batterystatus <= 100,
                      medium_strength_battery_image:
                        item.batterystatus >= 30 && item.batterystatus <= 69,
                      weak_strength_battery_image:
                        item.batterystatus >= 0 && item.batterystatus <= 29
                    }"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
          <div class="noData" *ngIf="refrigLocationList.length == 0">
            {{ "noData" | translate: "Common" }}
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Alarms' | translate: 'Refrigeration' }}">
          <div class="col-sm-12 p-0">
            <div class="row" style="margin: 0px">
              <div class="col-md-12 filter_section">
                <div class="row aic">
                  <div class="col-md-1">
                    <div class="text-uppercase font-weight700 font-size12">
                      {{ "filterBy" | translate: "Common" }}
                    </div>
                  </div>
                  <div class="col-md-3 tblsearch listsearch">
                    <div class="filtertitle pr-2">
                      {{ "search" | translate: "Common" }}
                    </div>
                    <input
                      type="text"
                      pInputText
                      size="50"
                      (input)="
                        dataTable.filterGlobal($event.target.value, 'contains')
                      "
                      placeholder="{{
                        'SearchAnythingPlaceholder' | translate: 'Site'
                      }}"
                    />
                  </div>
                  <div class="col-md-3 tblsearch listsearch">
                    <p class="filtertitle pr-2">
                      {{ "locationName" | translate: "Notification" }}
                    </p>
                    <p-multiSelect
                      [options]="cabinetList"
                      appendTo="body"
                      [(ngModel)]="cabinateName"
                      [defaultLabel]="selectLabel"
                      (onChange)="
                        notificationTable.filter(
                          $event.value,
                          'CabinetName',
                          'in'
                        )
                      "
                    ></p-multiSelect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt10">
              <div class="col-sm-12 p-0">
                <div class="content">
                  <div class="content-body configTable alarm_table">
                    <p-table
                      #notificationTable
                      [style]="{ overflow: 'auto!important' }"
                      [columns]="allcols"
                      [value]="notificationList"
                      [responsive]="true"
                      [rows]="10"
                      [paginator]="true"
                      [rowsPerPageOptions]="[10, 15, 20, 25]"
                    >
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th
                            *ngFor="let col of columns"
                            [pSortableColumn]="col.field"
                            pResizableColumn
                          >
                            <span
                              [style]="{
                                display: 'flex'
                              }"
                            >
                              {{ col.header }}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </span>
                          </th>
                          <th width="80px">
                            {{ "action" | translate: "Common" }}
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template
                        pTemplate="body"
                        let-rowData
                        let-item
                        let-i="rowIndex"
                        let-columns="columns"
                      >
                        <tr>
                          <td>{{ item.CabinetName | delimit: "50" }}</td>
                          <td>{{ item.RaisedOn | moment: "DateTime" }}</td>
                          <td>
                            <span
                              [ngClass]="{
                                success_message: item.IsDismissed,
                                warning_status: !item.IsDismissed
                              }"
                            >
                              {{ item.Status | translate: "Refrigeration" }}
                            </span>
                          </td>
                          <td>
                            {{
                              item.NotificationText
                                | delimit: "100"
                                | translate: "Notification"
                            }}
                          </td>
                          <td>
                            {{ item.ActionText }}
                          </td>
                          <td>
                            {{ item.Remarks | translate: "Notification" }}
                          </td>
                          <td>
                            {{ item.DismmisedOn | moment: "DateTime" }}
                          </td>
                          <td>
                            {{ item.DismissedBy }}
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-target="#DismissAlarm"
                              *ngIf="item.IsDismissed == false"
                              data-toggle="modal"
                              (click)="DismissAlarm(item)"
                            >
                              {{ "Resolve" | translate: "Notification" }}
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td [attr.colspan]="9">
                            <span class="noDataText">
                              {{ "noRecord" | translate: "Common" }}
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <div
    class="modal fade"
    id="DismissAlarm"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "ResolveAlarm" | translate: "Notification" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" (click)="clearPopUp()">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="addNotification.IsDismissed == false">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label for="Reason">
                {{ "reasons" | translate: "Reasons" }} :
              </label>
              <p-dropdown
                name="Reason"
                class="dropdown"
                [options]="Reasons"
                [(ngModel)]="selectedReason"
                [filter]="true"
                (onChange)="GetActionbyReasonID()"
              ></p-dropdown>
            </div>
            <div class="col-md-6 col-sm-6">
              <label for="Action">
                {{ "correctiveActions" | translate: "CorrectiveAction" }} :
              </label>
              <p-dropdown
                name="Action"
                class="dropdown"
                [options]="Actions"
                [(ngModel)]="selectedAction"
                [filter]="true"
              ></p-dropdown>
            </div>
            <div class="col-sm-12">
              <label for="Remarks">
                {{ "remark" | translate: "Common" }} :
              </label>
              <textarea
                name="Remarks"
                [(ngModel)]="RemarkText"
                class="form-control"
                cols="10"
                maxlength="200"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="addNotification.IsDismissed == true">
          <div class="row">
            <label for="Reason" class="col-sm-4">
              {{ "reasons" | translate: "Reasons" }} :
            </label>
            <p name="Reason" class="col-sm-8">
              {{ addNotification.ReasonText }}
            </p>
          </div>
          <div class="row">
            <label for="Action" class="col-sm-4">
              {{ "correctiveActions" | translate: "CorrectiveAction" }} :
            </label>
            <p name="Action" class="col-sm-8">
              {{ addNotification.ActionText }}
            </p>
          </div>
          <div class="row">
            <label for="Remarks" class="col-sm-4">
              {{ "remark" | translate: "Common" }} :
            </label>
            <p name="Remarks" class="col-sm-8">
              {{ addNotification.Remarks | translate: "Notification" }}
            </p>
          </div>
          <div class="row">
            <label for="dismissby" class="col-sm-4">
              {{ "DissmissedBy" | translate: "Common" }} :
            </label>
            <p name="dismissby" class="col-sm-8">
              {{ addNotification.DismissedBy }}
            </p>
          </div>
          <div class="row">
            <label for="dismisson" class="col-sm-4">
              {{ "Resolvedon" | translate: "Refrigeration" }} :
            </label>
            <p name="dismisson" class="col-sm-8">
              {{ addNotification.DismmisedOn | moment: "DateTime" }}
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            #addcloseBtn
            (click)="clearPopUp()"
            data-dismiss="modal"
          >
            {{ "close" | translate: "Common" }}
          </button>
          <button
            *ngIf="addNotification.IsDismissed == false"
            type="button"
            class="btn btn-primary"
            [disabled]="Validate()"
            (click)="SaveNotification()"
          >
            <span>{{ "Resolve" | translate: "Common" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
<div
  class="modal fade"
  id="clientStatusModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog exportModal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "exportReport" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="div_spacing">
          <p style="margin-bottom: 10px" class="font-14">
            {{ "chooseDateOption" | translate: "Common" }}
          </p>
          <div class="flex">
            <div *ngFor="let category of datepickerOption">
              <p-radioButton
                class="pr-2"
                [inputId]="category.key"
                name="dateoption"
                [value]="category"
                (onClick)="showDateSection(selectedCategory)"
                [(ngModel)]="selectedCategory"
              ></p-radioButton>
              <label
                style="
                  margin: 0px;
                  padding-right: 20px;
                  text-transform: uppercase;
                  font-size: 12px;
                "
                [for]="category.key"
              >
                {{ category.name | translate: "Common" }}
              </label>
            </div>
          </div>
        </div>
        <div
          *ngIf="showSingleDate"
          class="date-picker div_spacing"
          style="padding-top: 5px"
        >
          <label class="section_label">
            <p>{{ "date" | translate: "Refrigeration" }}</p>
            <p-calendar
              placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
              dateFormat="yy-mm-dd"
              [(ngModel)]="singleDate"
              [maxDate]="maxDateValue"
              [readonlyInput]="true"
              [showIcon]="true"
              inputId="icon"
              (onSelect)="singleDayPicker(singleDate)"
            ></p-calendar>
          </label>
        </div>
        <div
          *ngIf="showDateRange"
          class="date-picker div_spacing date-range_section"
        >
          <label class="section_label">
            <p>{{ "startdate" | translate: "Refrigeration" }}</p>
            <p-calendar
              placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
              dateFormat="yy-mm-dd"
              [(ngModel)]="fromDate"
              [maxDate]="maxDateValue"
              [readonlyInput]="true"
              [showIcon]="true"
              inputId="icon"
              (onSelect)="endDatePicker(fromDate)"
            ></p-calendar>
          </label>
          <label class="section_label">
            <p>{{ "enddate" | translate: "Refrigeration" }}</p>
            <p-calendar
              placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
              dateFormat="yy-mm-dd"
              [(ngModel)]="toDate"
              [minDate]="fromDate"
              [maxDate]="toMaxDate"
              [showIcon]="true"
              inputId="icon"
              (onSelect)="toDatePicker(toDate)"
            ></p-calendar>
          </label>
        </div>
        <p *ngIf="this.errorMsg.length > 0" style="color: red">
          {{ "noRecord " | translate: "Commom" }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "cancel" | translate: "Common" }}
        </button>
        <button type="button" class="btn btn-primary" (click)="getExportFile()">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>

          {{ "export" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>
